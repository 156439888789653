import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBWysiwyg = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.WYSIWYG),
  article: z.string(),
});

export default ZSCBWysiwyg;
