import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBImageWithLink = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.IMAGE_WITH_LINK),
  media: ZSuluMedia,
  has_fullwidth_image: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  link: z.string().or(z.null()),
});

export default ZSCBImageWithLink;
