import { z } from 'zod';

import {
  ESuluContentBlockTypes,
  ESuluPageTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBContent from './ZSCBContent';

const ZSCBPageCard = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluContentBlockTypes.PAGE),
  template: z.nativeEnum(ESuluPageTypes),
  content: ZSCBContent,
});

export default ZSCBPageCard;
