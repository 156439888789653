import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBHotspot from './ZSCBHotspot';
import ZSuluMedia from './ZSuluMedia';

const ZSCBBanner = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.BANNER),
  link: z.string(),
  image_map: z.object({
    image: ZSuluMedia,
    hotspots: z.array(ZSCBHotspot).optional(),
  }),
});

export default ZSCBBanner;
