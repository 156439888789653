import { z } from 'zod';

import {
  ESuluHotspotType,
  ESuluHotspotTypes,
} from 'types/enums/sulu/EBackendData';

const ZSCBHotspot = z.object({
  type: z.literal(ESuluHotspotType.BASIC),
  hotspot: z.object({
    type: z.nativeEnum(ESuluHotspotTypes),
    left: z.number(),
    top: z.number(),
    radius: z.number().optional(),
    width: z.number().optional(),
    height: z.number().optional(),
  }),
  title: z.string().optional(),
});

export default ZSCBHotspot;
