import React from 'react';
import { useTranslation } from 'react-i18next';

const TechCloud = () => {
  const { t } = useTranslation();
  return (
    <div className='text-center'>
      <h3 className='mb-9'>
        {t('components.banner.techCloud.weSupportYou')}
      </h3>
        <h3 className='font-light'>
            Pro-Code | Java | Spring | HCL Commerce |<br/>
            PHP | Symfony | Sylius | Sulu | TypeScript | React |<br/>
            Low-Code | Intrexx | Volt MX | Mendix | PlantAnApp |<br/>
            Event Storming | Event Sourcing | prooph Board |<br/>
            Agile | Consulting | Development | Operations
        </h3>
    </div>
  );
};
export default TechCloud;
