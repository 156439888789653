import { z } from 'zod';

import {
  ESuluContentBlockBackgroundVariants,
  ESuluDataTypes,
  ESuluSnippetTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBCard from './contentBlocks/ZSCBCard';
import ZSCBPageCard from './contentBlocks/ZSCBPageCard';
import ZSuluMedia from './contentBlocks/ZSuluMedia';

/**
 * MIND: ZSS = Zod Sulu Snippet
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

const ZSuluSnippetBaseData = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluDataTypes.SNIPPET),
});

export const ZSSPageHeader = ZSuluSnippetBaseData.extend({
  template: z.literal(ESuluSnippetTypes.PAGE_HEADER),
  content: z.object({
    title: z.string(),
    subtitle: z.string(),
    image: ZSuluMedia,
    button_link_1: z.string().or(z.null()),
    button_link_2: z.string().or(z.null()),
    button_text_1: z.string().or(z.null()),
    button_text_2: z.string().or(z.null()),
    customer_banner_title: z.string(),
    customer_banner_images: z.array(ZSuluMedia),
    isHeroHeader: z.boolean().or(z.null()),
  }),
});

export const ZSSCardSlider = ZSuluSnippetBaseData.extend({
  template: z.literal(ESuluSnippetTypes.CARD_SLIDER),
  content: z.object({
    title: z.string().or(z.null()),
    cards: z.array(ZSCBPageCard),
  }),
});

export const ZSSAboutCustomer = ZSuluSnippetBaseData.extend({
  template: z.literal(ESuluSnippetTypes.ABOUT_CUSTOMER),
  content: z.object({
    background_variant: z
      .nativeEnum(ESuluContentBlockBackgroundVariants)
      .or(z.null()),
    title: z.string(),
    subtitle: z.string(),
    description: z.string(),
    card: z.array(ZSCBCard),
  }),
});

// change to z.union([ZSSPageHeader, ...]) once there is more than one type
export const ZSuluSnippet = z.union([
  ZSSPageHeader,
  ZSSAboutCustomer,
  ZSSCardSlider,
]);

export type ISSPageHeader = z.infer<typeof ZSSPageHeader>;
export type ISSAboutCustomer = z.infer<typeof ZSSAboutCustomer>;
export type ISuluSnippet = z.infer<typeof ZSuluSnippet>;
