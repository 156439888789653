// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import { CorruptSnippet } from 'pages';
import React from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import { AboutCustomer, PageHeader } from 'components/snippets';

import { ESuluSnippetTypes } from 'types/enums/sulu/EBackendData';
import {
  ISuluSnippet,
  ZSSAboutCustomer, // ZSSCardSlider,
  ZSSPageHeader,
} from 'types/interfaces/sulu/ISnippets';

const parseSnippetData = (
  snippetData: ISuluSnippet,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: snippetData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const snippetDataJS = toJS(snippetData);
  const zodParsed = zodType.safeParse(snippetDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `snippet-${snippetData.template}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (snippetData: ISuluSnippet): JSX.Element => {
  const { template } = snippetData;
  switch (template) {
    case ESuluSnippetTypes.PAGE_HEADER:
      return parseSnippetData(snippetData, ZSSPageHeader) ? (
        <PageHeader {...snippetData} />
      ) : (
        <CorruptSnippet />
      );
    case ESuluSnippetTypes.ABOUT_CUSTOMER:
      return parseSnippetData(snippetData, ZSSAboutCustomer) ? (
        <AboutCustomer {...snippetData} />
      ) : (
        <CorruptSnippet />
      );
    default:
      return <CorruptSnippet />;
  }
};
