import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowScroll } from 'react-use';

import ChevronLeft from 'assets/icons/ChevronLeft.svg';
import ChevronRight from 'assets/icons/ChevronRight.svg';

import LanguageSwitch from 'components/static/LanguageSwitch';

import {
  INavigation,
  INavigationItem,
} from 'types/interfaces/sulu/INavigation';

import getCurrentLanguage from 'utils/sulu/getCurrentLanguage';

interface IMenu {
  menuItems: INavigation[];
}

const isActive = (
  menuItem: INavigation | INavigationItem,
  isParentItem: boolean = false,
): boolean => {
  if (!isParentItem && 'children' in menuItem && menuItem.children.length > 0) {
    return window.location.pathname.startsWith(
      `/${menuItem.locale}${menuItem.url}`,
    );
  }

  return window.location.pathname === `/${menuItem.locale}${menuItem.url}`;
};

export default ({ menuItems }: IMenu) => {
  const { t } = useTranslation();
  const { y: windowScrollY } = useWindowScroll();
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<INavigation | undefined>(
    undefined,
  );
  const [showChildItems, setShowChildItems] = useState(false);
  const [hoverItemId, setHoverItemId] = useState<string>('');

  useEffect(() => {
    if (!showSidebar) {
      setShowChildItems(false);
    }
  }, [showSidebar]);

  useEffect(() => {
    if (!showChildItems) {
      // 300ms = duration of animation
      setTimeout(() => {
        setActiveMenuItem(undefined);
      }, 300);
    }
  }, [showChildItems]);

  useEffect(() => {
    if (activeMenuItem) {
      setShowChildItems(true);
    }
  }, [activeMenuItem]);

  const menuItemContainerClassName =
    'absolute top-0 right-0 bottom-0 left-0 pr-5 pl-14 overflow-y-auto overflow-x-hidden transition-transform ease-in-out duration-300';

  const menuItemClassName =
    'hyphens-none flex items-center justify-start gap-x-5 text-left hover:text-white font-sans text-3xl leading-none py-5 uppercase';

  const getChildMenuItem = (menuItem: INavigation | INavigationItem) => {
    const isParentItem = 'children' in menuItem && menuItem.children.length > 0;

    return (
      <a
        key={menuItem.id}
        className={`hyphens-none flex items-center justify-start text-left hover:text-white font-sans font-normal text-[28px] leading-none py-4 ${
          isActive(menuItem, isParentItem) ? 'text-white' : 'text-white/50'
        }`}
        href={`/${menuItem.locale}${menuItem.url}`}
        onClick={() => setShowSidebar(false)}
      >
        {menuItem.title}
      </a>
    );
  };

  return (
    <>
      <div
        className={`fixed top-0 inset-x-0 z-[9999] h-14 flex items-center justify-center text-white transition-colors ease-in-out duration-300 ${
          windowScrollY > 10 && !showSidebar
            ? 'backdrop-blur-sm bg-primary/90 shadow-md'
            : 'bg-transparent'
        }`}
      >
        <button
          type='button'
          onClick={() => setShowSidebar(!showSidebar)}
          className='absolute left-4 top-1/2 -translate-y-1/2 focus:outline-none'
        >
          <span className='sr-only'>Open main menu</span>
          <div className='flex flex-col w-8'>
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out mb-1.5 ${
                showSidebar ? 'rotate-45 translate-y-2.5' : ''
              }`}
            />
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out ${
                showSidebar ? 'opacity-0' : ''
              }`}
            />
            <span
              aria-hidden='true'
              className={`block h-1 w-full bg-white rounded-full transition duration-300 ease-in-out mt-1.5 ${
                showSidebar ? '-rotate-45 -translate-y-2.5' : ''
              }`}
            />
          </div>
        </button>
      </div>

      <div
        className={`fixed z-[9998] top-0 left-0 pt-28 pb-10 w-full max-w-full h-full overflow-auto backdrop-blur-sm bg-primary/90 shadow-md ease-in-out duration-300 ${
          showSidebar ? 'translate-x-0 ' : '-translate-x-full'
        }`}
      >
        <div className='flex flex-col justify-between gap-10 h-full'>
          <div className='relative flex-1 overflow-hidden'>
            {/* level 1 */}
            <div
              className={`${menuItemContainerClassName} ${
                showChildItems ? '-translate-x-full' : 'translate-x-0'
              }`}
            >
              <div className='flex flex-col'>
                <a
                  className={`${menuItemClassName} ${
                    window.location.pathname === `/${getCurrentLanguage}`
                      ? 'text-white font-bold'
                      : 'text-white/50 font-medium'
                  }`}
                  href={`/${getCurrentLanguage}`}
                  onClick={() => setShowSidebar(false)}
                >
                  {t('navigation.home')}
                </a>
                {menuItems.map(menuItem => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    key={menuItem.id}
                    className={`${menuItemClassName} ${
                      isActive(menuItem)
                        ? 'text-white font-bold'
                        : 'text-white/50 font-medium'
                    }`}
                    href={
                      menuItem.children.length === 0
                        ? `/${menuItem.locale}${menuItem.url}`
                        : ''
                    }
                    onClick={() =>
                      menuItem.children.length === 0
                        ? setShowSidebar(false)
                        : setActiveMenuItem(menuItem)
                    }
                    onMouseEnter={() => setHoverItemId(menuItem.id)}
                    onMouseLeave={() => setHoverItemId('')}
                  >
                    <span className='flex-1'>{menuItem.title}</span>
                    {menuItem.children.length > 0 && (
                      <ChevronRight
                        className={`ease-in-out duration-300 ${
                          isActive(menuItem) || hoverItemId === menuItem.id
                            ? 'opacity-100'
                            : 'opacity-50'
                        }`}
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
            {/* level 2 */}
            <div
              className={`${menuItemContainerClassName} ${
                showChildItems ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <div className='flex flex-col'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={`${menuItemClassName} text-white font-bold mb-10`}
                  href=''
                  onClick={() => setShowChildItems(false)}
                >
                  <ChevronLeft />
                  {t('navigation.back')}
                </a>
                {activeMenuItem && (
                  <>
                    {getChildMenuItem(activeMenuItem)}
                    {activeMenuItem.children.map(childMenuItem =>
                      getChildMenuItem(childMenuItem),
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* language switch */}
          <div className='flex justify-center px-10'>
            <LanguageSwitch />
          </div>
        </div>
      </div>
    </>
  );
};
