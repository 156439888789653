import { t } from 'i18next';
import { nanoid } from 'nanoid';
import React from 'react';

export interface ITeam {
  title: string;
  intros: string[];
  copy: string;
}

const Team = ({ title, intros, copy }: ITeam) => {
  const createTeamIntros = () =>
    intros.map(introText => {
      const introHeader = t('components.team.weAre');

      return (
        <div key={nanoid()} className='mt-4'>
          <h3 className='font-raleway font-light'>
            <strong>{introHeader}</strong> {introText}
          </h3>
        </div>
      );
    });

  return (
    <div>
      <h3 className='uppercase mb-8 lg:mb-9'>{title}</h3>
      <div className='mb-3'>{createTeamIntros()}</div>
      <p>{copy}</p>
    </div>
  );
};

export default Team;
