import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBCard = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.CARD),
  image: ZSuluMedia.or(z.null()),
  title: z.string().or(z.null()),
  description_html: z.string().or(z.null()),
  button_link: z.string().or(z.null()),
  open_in_new_tab: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  button_text: z.string().or(z.null()),
});

export default ZSCBCard;
