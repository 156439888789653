import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deTranslations from './de.json';
import enTranslations from './en.json';

// eslint-disable-next-line import/prefer-default-export
export const resources = {
  en: {
    translation: enTranslations,
  },
  de: {
    translation: deTranslations,
  },
} as const;

const htmlTag = document.querySelector('html');
const lang = htmlTag ? htmlTag.getAttribute('lang') : 'de';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: lang || 'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources,
  });
