import { Options } from '@splidejs/react-splide';
import { nanoid } from 'nanoid';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardIcon from 'components/primitives/CardIcon';
import Slider from 'components/primitives/Slider';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { ESuluCardSliderVariants } from 'types/enums/sulu/EBackendData';
import { ISCBSliderSelector } from 'types/interfaces/sulu/IContentBlocks';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';
import scrollToElement from 'utils/scrollToElement';

import Card from './Card';

/**
 * Generates an "upper" slider that controls a "lower" slider.
 * To distinguish which "lower" slider is shown, we are using
 * the index of the map method which should always be appropriate.
 */
const SliderSelector = ({ variant, title, icon_cards }: ISCBSliderSelector) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToElement(scrollToElementRef, false);
  }, [activeIndex]);

  const getPerPageOption = (): number => {
    const numberOfCards = icon_cards.length;

    if (variant === ESuluCardSliderVariants.DOTS) {
      return numberOfCards > 3 ? 3 : numberOfCards;
    }

    return numberOfCards > 4 ? 4 : numberOfCards;
  };

  const cardIconSliderOptions: Options = {
    perPage: getPerPageOption(),
    breakpoints: {
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) - 1]: {
        perPage: 1,
        drag: true,
      },
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) - 1]: {
        perPage: 2,
        drag: true,
      },
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.xl) - 1]: {
        perPage: 3,
        drag: true,
      },
    },
  };

  const openSubSlider = (event: MouseEvent, index: number) => {
    event.preventDefault();
    setActiveIndex(index);
  };

  const createSubSlider = () =>
    icon_cards.map((icon_card, i) => {
      if (i === activeIndex) {
        if (icon_card.cards.length > 0) {
          return (
            <Slider
              key={icon_card.title}
              options={{
                perPage: icon_card.cards.length >= 3 ? 3 : 2,
                breakpoints: {
                  [getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) - 1]: {
                    perPage: 1,
                    drag: true,
                  },
                  [getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) - 1]: {
                    perPage: 2,
                    drag: true,
                  },
                },
              }}
            >
              {icon_card.cards.map(card => (
                <Card
                  key={`${card.content.title}-${nanoid()}`}
                  {...card.content}
                />
              ))}
            </Slider>
          );
        }

        return (
          <p className='text-xl text-center text-black/50'>
            {t('general.noDataAvailable')}
          </p>
        );
      }

      return null;
    });

  return (
    <div>
      {title && <h3 className='text-center mb-9'>{title}</h3>}
      <Slider options={cardIconSliderOptions}>
        {icon_cards.map((icon_card, i) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={`${icon_card.title}-${nanoid()}`}
            className={`h-full hover:opacity-100 transition-opacity ease-in-out duration-300${
              activeIndex !== i ? ' opacity-50' : ''
            }`}
            onClick={event => {
              openSubSlider(event, i);
            }}
          >
            <CardIcon
              icon={icon_card.icon?.url}
              title={icon_card.title || ''}
              copy={icon_card.description_html || ''}
              button={{
                label: icon_card.button_text || '',
                url: '#',
                onClick: event => {
                  openSubSlider(event, i);
                },
              }}
            />
          </div>
        ))}
      </Slider>
      <div ref={scrollToElementRef} className='mt-12 lg:mt-20 scroll-mt-32'>
        {createSubSlider()}
      </div>
    </div>
  );
};

export default SliderSelector;
