import React from 'react';

import ButtonLink from 'components/primitives/ButtonLink';

import { ISCBImageWithText } from 'types/interfaces/sulu/IContentBlocks';

interface IImageWithText extends ISCBImageWithText {
  orientation: 'left' | 'right';
}

export default ({
  image,
  image_title,
  image_description,
  orientation,
  button_text,
  button_link,
  open_in_new_tab,
  image_contain,
  image_title_uppercase,
  background_variant: backgroundVariant,
}: IImageWithText) => {
  const bgImageStyle = {
    paddingTop: '56.25%', // aspect ratio 16:9
    backgroundImage: `url(${image.url})`,
  } as React.CSSProperties;

  return (
    <div
      className={`grid lg:grid-cols-2 theme-${backgroundVariant || 'default'}`}
    >
      <div
        className={`py-14 pl-10 pr-10 lg:pl-28 lg:pr-20 order-2 ${
          orientation !== 'right' ? 'lg:order-none' : ''
        }`}
      >
        {image_title && (
          <h2 className={`${image_title_uppercase ? 'uppercase' : ''}`}>
            {image_title}
          </h2>
        )}
        {image_description && (
          <p
            className='font-light mt-8'
            dangerouslySetInnerHTML={{ __html: image_description }}
          />
        )}
        {button_text && button_link && (
          <div className='lg:w-3/4 mt-11'>
            <ButtonLink
              label={button_text}
              url={button_link}
              target={open_in_new_tab ? '_blank' : '_self'}
            />
          </div>
        )}
      </div>
      <div
        className={`order-1 lg:order-none ${
          image_contain ? 'bg-contain' : 'bg-cover'
        } bg-center bg-no-repeat`}
        style={bgImageStyle}
      />
    </div>
  );
};
