import { z } from 'zod';

import ZSuluMedia from './ZSuluMedia';

const ZSPContentBase = z.object({
  title: z.string(),
  url: z.string().or(z.null()),
});

const ZSCBContent = ZSPContentBase.extend({
  open_in_new_tab: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  card_image: ZSuluMedia.optional().or(z.null()),
  card_title: z.string().optional(),
  card_description_html: z.string().optional(),
  card_button_text: z.string().optional(),
});

export default ZSCBContent;
