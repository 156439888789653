import React from 'react';

import { ISCBWysiwyg } from 'types/interfaces/sulu/IContentBlocks';

const Wysiwyg = ({ article }: Pick<ISCBWysiwyg, 'article'>) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: article }} />
);

export default Wysiwyg;
