import { nanoid } from 'nanoid';
import React, { useState } from 'react';

import { ISuluFormInput } from 'types/interfaces/sulu/IForm';

import Chip, { IChip } from './Chip';

export interface IChipComponent {
  data: ISuluFormInput;
  setSelectedChips: React.Dispatch<React.SetStateAction<IChip[]>>;
}

const ChipComponent = ({ data, setSelectedChips }: IChipComponent) => {
  const [chips, setChips] = useState<IChip[]>(
    data.choices.map((choice: string) => ({
      selected: false,
      label: choice,
    })),
  );

  const onChipClick = (chip: IChip) => {
    const newChips = chips.map(oldChip =>
      oldChip.label === chip.label
        ? { ...oldChip, selected: !oldChip.selected }
        : oldChip,
    );
    setChips(newChips);
    setSelectedChips(newChips.filter(item => item.selected));
  };

  const createChips = () =>
    chips.map((chip: IChip) => (
      <Chip
        key={`${chip}_${nanoid()}`}
        chip={chip}
        onChipClick={() => onChipClick(chip)}
      />
    ));

  return (
    <div className='mt-4'>
      <p className='font-light text-primary-text md:text-xl'>{data.label}</p>
      <div className='flex items-center flex-wrap'>{createChips()}</div>
    </div>
  );
};

export default ChipComponent;
