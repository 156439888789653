import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBImageWithText = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.IMAGE_WITH_TEXT),
  image: ZSuluMedia,
  image_title: z.string().or(z.null()),
  image_description: z.string().or(z.null()),
  button_link: z.string().or(z.null()),
  open_in_new_tab: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  button_text: z.string(),
  image_contain: z.boolean(),
  image_title_uppercase: z.boolean(),
});

export default ZSCBImageWithText;
