// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import {
  CorruptPage,
  Default,
  ErrorDefault,
  Job,
  Project,
  ProjectsArticles,
} from 'pages';
import React from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import { ESuluPageTypes } from 'types/enums/sulu/EBackendData';
import {
  ISuluPage,
  ZSPDefaultPageOnly,
  ZSPJobPageOnly,
  ZSPProject,
  ZSPProjectsArticles,
} from 'types/interfaces/sulu/IPages';

const parsePageData = (
  pageData: ISuluPage,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: pageData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const pageDataJS = toJS(pageData);
  const zodParsed = zodType.safeParse(pageDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `page-${pageData.template}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (pageData: ISuluPage): JSX.Element => {
  const { template } = pageData;
  switch (template) {
    case ESuluPageTypes.DEFAULT:
      return parsePageData(pageData, ZSPDefaultPageOnly) ? (
        <Default {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.JOB:
      return parsePageData(pageData, ZSPJobPageOnly) ? (
        <Job {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.PROJECT:
      return parsePageData(pageData, ZSPProject) ? (
        <Project {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.PROJECTS_ARTICLES:
      return parsePageData(pageData, ZSPProjectsArticles) ? (
        <ProjectsArticles {...pageData} />
      ) : (
        <CorruptPage />
      );
    default:
      return <ErrorDefault />;
  }
};
