import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

import './Slider.css';

interface ISlider {
  children: ReactElement[];
  options?: Options;
  hasLightPagination?: boolean;
}

const Slider = ({ children, options, hasLightPagination }: ISlider) => {
  const createSlides = () =>
    children.map(child => (
      <SplideSlide key={`slide_${nanoid()}`}>{child}</SplideSlide>
    ));

  return (
    <Splide
      options={{
        arrows: false,
        autoplay: true,
        rewind: true,
        breakpoints: {
          [getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) - 1]: {
            perPage: 1,
            drag: true,
          },
          [getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) - 1]: {
            perPage: 2,
            drag: true,
          },
        },
        gap: '1rem',
        ...options,
      }}
      className={hasLightPagination ? 'slider-light-pagination' : ''}
    >
      {createSlides()}
    </Splide>
  );
};

Slider.defaultProps = {
  options: {
    perPage: 3,
    drag: false,
  },
  hasLightPagination: false,
};

export default Slider;
