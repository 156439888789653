import { t } from 'i18next';
import React from 'react';

const title = t('components.timeline.title');

const items = [
  {
    year: t('components.timeline.2003.year'),
    description: t('components.timeline.2003.description'),
  },
  {
    year: t('components.timeline.2007.year'),
    description: t('components.timeline.2007.description'),
  },
  {
    year: t('components.timeline.2017.year'),
    description: t('components.timeline.2017.description'),
  },
  {
    year: t('components.timeline.2019.year'),
    description: t('components.timeline.2019.description'),
  },
];

export default () => (
  <div>
    <h3 className='uppercase text-center mb-7'>{title}</h3>
    <div className='flex gap-4'>
      {items.map(item => (
        <div key={item.year} className='flex-1 text-center'>
          <h3 className='font-bold font-raleway m-1'>{item.year}</h3>
          <h3 className='p-1 mt-2 font-light'>{item.description}</h3>
        </div>
      ))}
    </div>
  </div>
);
