import React from 'react';

import { ISPDefault } from 'types/interfaces/sulu/IPages';

import suluBlockHandler from 'utils/sulu/blockHandler';
import suluContactFormHandler from 'utils/sulu/contactFormHandler';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default ({ content }: ISPDefault) => (
  <div>
    {content.header && suluSnippetHandler(content.header)}
    {content.content_blocks.map((block, i) => suluBlockHandler(block, i))}
    {content.contact_form && suluContactFormHandler(content.contact_form)}
  </div>
);
