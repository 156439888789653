import { z } from 'zod';

import {
  ESuluContentBlockTextSizes,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBText = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TEXT),
  has_narrow_width: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  has_centered_title: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  text_size: z
    .nativeEnum(ESuluContentBlockTextSizes)
    .or(z.null()) // null is default
    .or(z.undefined()),
  title: z.string().or(z.null()),
  article: z.string(),
  button_link: z.string().or(z.null()),
  open_in_new_tab: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  button_text: z.string(),
  has_centered_aligned_button: z.boolean(),
});

export default ZSCBText;
