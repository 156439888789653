import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTextRow = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.TEXT_ROW),
  text: z.string(),
});

export default ZSCBTextRow;
