import React from 'react';

import { ISPProject } from 'types/interfaces/sulu/IPages';

type IProjectIntro = Pick<
  ISPProject['content'],
  'customer_name' | 'project_name' | 'intro_html' | 'intro_media'
>;

export default ({
  customer_name,
  project_name,
  intro_html,
  intro_media,
}: IProjectIntro) => (
  <div>
    <h1 className='text-center mb-8'>
      <span className='font-bold'>{customer_name}</span> |{' '}
      <span className='font-light'>{project_name}</span>
    </h1>
    <div className='grid lg:grid-cols-2'>
      {intro_media && intro_media.isImage && (
        <div className='lg:px-20'>
          <img alt={intro_media?.title} src={intro_media?.url} />
        </div>
      )}
      {intro_media && intro_media.isVideo && (
        <div className='lg:px-20'>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video src={intro_media?.url} />
        </div>
      )}
      <div
        className='mt-8 lg:mt-0 lg:px-20'
        dangerouslySetInnerHTML={{ __html: intro_html }}
      />
    </div>
  </div>
);
