import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  ESuluFormFields,
  ESuluFormMetaFields,
} from 'types/enums/sulu/EBackendData';
import { ISuluContactForm } from 'types/interfaces/sulu/IPages';

import Form from './Form';

/**
 * Checks if mandatory Sulu meta form data exists.
 */
export const validateMetaData = (content: ISuluContactForm): boolean => {
  const requiredMetaFields: ESuluFormMetaFields[] = [
    ESuluFormMetaFields.FORM_ID,
    ESuluFormMetaFields.FORM_NAME,
    ESuluFormMetaFields.CHECKSUM,
  ];
  const metaData: ESuluFormMetaFields[] = content.content.form[0].meta.map(
    formFields => formFields.property_path,
  );
  return requiredMetaFields.some(requiredField =>
    metaData.includes(requiredField),
  );
};

export const getFormIdFromMetaData = (content: ISuluContactForm): number => {
  const formId = content.content.form[0].meta.find(
    formFields => formFields.property_path === ESuluFormMetaFields.FORM_ID,
  );

  return Number(formId?.data) || 0;
};

export const getFormNameFromMetaData = (content: ISuluContactForm): string => {
  const formName = content.content.form[0].meta.find(
    formFields => formFields.property_path === ESuluFormMetaFields.FORM_NAME,
  );

  return String(formName?.data) || '';
};

export const generateFieldNameForSulu = (
  formName: string,
  formId: number,
  propertyPath: string,
) => `dynamic_${formName}${formId}[${propertyPath}]`;

const FormContainer = ({ content }: { content: ISuluContactForm }) => {
  /**
   * Introducing a `submitCounter` to reset the FormContainer.
   * The `submitCounter` will be used as a `key`.
   * This way, we are making sure that the whole state of the FormContainer
   * is being reset. This is needed as we save e.g. the state of the attachments
   * in the FormContainer itself which is NOT being reset by the
   * `reset()` function of react-hook-form.
   */
  const [submitCounter, setSubmitCounter] = useState(0);

  const onSubmit = (fieldValues: FieldValues) => {
    const formData = new FormData();
    const formId = getFormIdFromMetaData(content);
    const formName = getFormNameFromMetaData(content);

    // Add the input values of the form
    Object.entries(fieldValues).forEach(([propertyPath, value]) =>
      formData.append(
        generateFieldNameForSulu(formName, formId, propertyPath),
        propertyPath.includes(ESuluFormFields.ATTACHMENT) ? value[0] : value,
      ),
    );
    // Add the required metadata of the form
    content.content.form[0].meta.map(metaField =>
      formData.append(
        generateFieldNameForSulu(formName, formId, metaField.property_path),
        metaField.data.toString(),
      ),
    );
    // Add a "submit" value (seems to be required by sulu)
    formData.append(
      generateFieldNameForSulu(formName, formId, 'submit'),
      'null',
    );

    fetch(window.location.href, {
      method: 'POST',
      body: formData,
    }).then(res => {
      if (res.ok) {
        setSubmitCounter(submitCounter + 1);
      }
    });
  };

  return validateMetaData(content) ? (
    <Form key={submitCounter} content={content} onSubmit={onSubmit} />
  ) : (
    <h2>Form meta data missing.</h2>
  );
};

export default FormContainer;
