// @ts-ignore
import { startApp } from 'sulu-headless-bundle';
// @ts-ignore
import viewRegistry from 'sulu-headless-bundle/src/registries/viewRegistry';

import HeadlessErrorPage from 'views/HeadlessErrorPage';
import HeadlessTemplatePage from 'views/HeadlessTemplatePage';

import './index.css';

// register views for rendering page templates
// viewRegistry.add('page', 'headless-template', HeadlessTemplatePage);
viewRegistry.add('page', 'default', HeadlessTemplatePage);
viewRegistry.add('page', 'job', HeadlessTemplatePage);
viewRegistry.add('page', 'project', HeadlessTemplatePage);
viewRegistry.add('page', 'projects_articles', HeadlessTemplatePage);
viewRegistry.add('error', '404', HeadlessErrorPage);

// register views for rendering article templates

// start react application in specific DOM element
startApp(document.getElementById('sulu-headless-container'));
