// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorDefault, Error404 } from 'pages';
import React from 'react';

export default (pageData: { type: string; template: string }): JSX.Element => {
  const { template } = pageData;
  switch (template) {
    case '404':
      return <Error404 />;

    default:
      return <ErrorDefault />;
  }
};
