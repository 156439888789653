import React, { useEffect, useState } from 'react';

import { ESuluHotspotTypes } from 'types/enums/sulu/EBackendData';
import { ISCBBanner, ISCBHotspot } from 'types/interfaces/sulu/IContentBlocks';

type IHotspot = {
  type: string;
  left: number;
  top: number;
  radius?: number;
  width?: number;
  height?: number;
};

export default ({ link, image_map }: ISCBBanner) => {
  const [scale, setScale] = useState(
    window.innerWidth / image_map.image.properties.width,
  );
  const [imageHeight, setImageHeight] = useState(
    image_map.image.properties.height * scale,
  );
  const [imageWidth, setImageWidth] = useState(
    image_map.image.properties.width * scale,
  );

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newScale = window.innerWidth / image_map.image.properties.width;
      setScale(newScale);
      setImageHeight(image_map.image.properties.height * newScale);
      setImageWidth(image_map.image.properties.width * newScale);
    };
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const getStyleForShape = ({
    type,
    left,
    top,
    radius,
    width,
    height,
  }: IHotspot) => {
    const leftPx = left * imageWidth;
    const topPx = top * imageHeight;
    const radiusPx = radius ? radius * imageWidth : 0;
    const widthPx = width ? width * imageWidth : 0;
    const heightPx = height ? height * imageHeight : 0;

    if (type === ESuluHotspotTypes.CIRCLE) {
      return {
        top: topPx,
        left: leftPx,
        width: radiusPx,
        height: radiusPx,
        borderRadius: radiusPx,
      };
    }
    if (type === ESuluHotspotTypes.POINT) {
      return {
        top: topPx,
        left: leftPx,
        width: 30,
        height: 30,
        borderRadius: 30,
      };
    }

    return {
      top: topPx,
      left: leftPx,
      width: widthPx,
      height: heightPx,
    };
  };

  return (
    <div className='relative flex flex-1'>
      <img
        src={image_map.image.url}
        alt={image_map.image.description || image_map.image.title}
        className='flex'
        width={imageWidth}
        height={imageHeight}
      />
      {image_map.hotspots?.map((hotspot: ISCBHotspot, index: number) => (
        <div
          className='absolute z-10'
          style={{
            ...getStyleForShape(hotspot.hotspot),
          }}
          onClick={() => {
            window.open(link);
          }}
          onKeyDown={() => {
            window.open(link);
          }}
          key={hotspot.title}
          tabIndex={index}
          role='button'
          aria-label='button'
        />
      ))}
    </div>
  );
};
