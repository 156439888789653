import { nanoid } from 'nanoid';
import React from 'react';

import { ISCBMultipleImageText } from 'types/interfaces/sulu/IContentBlocks';

import ImageWithText from './ImageWithText';

const MultipleImageText = ({ title, images }: ISCBMultipleImageText) => (
  <>
    {title && <h3 className='text-center mb-10'>{title}</h3>}
    {images.map((imageWithText, i) => (
      <ImageWithText
        key={nanoid()}
        orientation={i % 2 ? 'left' : 'right'}
        {...imageWithText}
      />
    ))}
  </>
);

export default MultipleImageText;
