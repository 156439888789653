import React from 'react';

import { ISCBText } from 'types/interfaces/sulu/IContentBlocks';

import ButtonLink from '../primitives/ButtonLink';

const Text = ({
  title,
  article,
  button_text,
  button_link,
  open_in_new_tab,
  has_centered_title,
  has_centered_aligned_button,
}: ISCBText) => (
  <>
    {title && (
      <h2 className={`${has_centered_title ? 'text-center' : ''}`}>{title}</h2>
    )}
    <div dangerouslySetInnerHTML={{ __html: article }} />
    {button_text && button_link && (
      <div className='mt-6'>
        <ButtonLink
          label={button_text}
          url={button_link}
          target={open_in_new_tab ? '_blank' : '_self'}
          alignCenter={has_centered_aligned_button}
        />
      </div>
    )}
  </>
);

export default Text;
