import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { ESuluFormFields } from 'types/enums/sulu/EBackendData';
import { ISuluFormInput } from 'types/interfaces/sulu/IForm';
import { ISuluApplyForm } from 'types/interfaces/sulu/IPages';

import {
  generateFieldNameForSulu,
  getFormIdFromMetaData,
  getFormNameFromMetaData,
} from '../form';
import generateFormField from '../form/generateFormField';

export default ({ applyFormData }: { applyFormData: ISuluApplyForm }) => {
  const data = applyFormData.content.form[0];
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  const onSubmit = async (fieldValues: FieldValues) => {
    const formData = new FormData();
    const formId = getFormIdFromMetaData(applyFormData);
    const formName = getFormNameFromMetaData(applyFormData);

    Object.entries(fieldValues).forEach(([propertyPath, value]) => {
      if (propertyPath === ESuluFormFields.DROPDOWN) {
        formData.append(
          generateFieldNameForSulu(formName, formId, ESuluFormFields.DROPDOWN),
          value,
        );
      } else if (propertyPath === ESuluFormFields.CHECKBOX) {
        const checkboxValue = value === '1' ? 'true' : 'false';
        formData.append(
          generateFieldNameForSulu(formName, formId, propertyPath),
          checkboxValue,
        );
      } else if (!propertyPath.includes(ESuluFormFields.ATTACHMENT)) {
        formData.append(
          generateFieldNameForSulu(formName, formId, propertyPath),
          value,
        );
      }
    });

    data.meta.map(metaField =>
      formData.append(
        generateFieldNameForSulu(formName, formId, metaField.property_path),
        metaField.data.toString(),
      ),
    );

    const response = await fetch(window.location.href, {
      method: 'POST',
      body: formData,
    });

    console.log(response);
  };

  const { register, handleSubmit } = useForm();

  const XOR = (a: boolean, b: boolean): boolean => (a || b) && !(a && b);

  const contactDetails: { [key: string]: ISuluFormInput } = {};
  const applicationDetails: { [key: string]: ISuluFormInput } = {};
  let idx = 0;
  data.inputs.forEach(item => {
    if (
      !XOR(
        item.type !== ESuluFormFields.CHECKBOX,
        item.type !== ESuluFormFields.ATTACHMENT,
      )
    ) {
      contactDetails[item.type] = item;
    } else {
      applicationDetails[idx] = item;
      idx += 1;
    }
  });

  return (
    <div>
      <h3 className='text-center uppercase mb-12'>
        {applyFormData.content.title}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-4'>
        <div className='grid grid-cols-2 col-span-2'>
          <div className='grid col-span-2'>
            <h3 className='text-center uppercase mb-12'>
              Your contact details
            </h3>
          </div>
          <div className='mx-2'>
            {generateFormField(
              contactDetails[ESuluFormFields.FIRST_NAME],
              register,
              'firstName',
            )}
            {generateFormField(
              contactDetails[ESuluFormFields.LAST_NAME],
              register,
              'lastName',
            )}
            {generateFormField(
              contactDetails[ESuluFormFields.EMAIL],
              register,
              'email',
            )}
          </div>
          <div className='mx-2'>
            {generateFormField(
              contactDetails[ESuluFormFields.STREET],
              register,
              'street',
            )}
            {generateFormField(
              contactDetails[ESuluFormFields.CITY],
              register,
              'city',
            )}
            {generateFormField(
              contactDetails[ESuluFormFields.STATE],
              register,
              'state',
            )}
          </div>
          <div className='grid col-span-2'>
            {generateFormField(
              contactDetails[ESuluFormFields.TEXTAREA],
              register,
              'textarea',
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 col-span-2'>
          <div className='grid col-span-2'>
            <h3 className='text-center uppercase mb-12'>Your application</h3>
          </div>
          <div>
            {generateFormField(
              applicationDetails[0],
              register,
              applicationDetails[0].property_path,
            )}
            {generateFormField(
              applicationDetails[1],
              register,
              applicationDetails[1].property_path,
            )}
          </div>
          <div>
            {generateFormField(
              applicationDetails[2],
              register,
              applicationDetails[2].property_path,
            )}
            {generateFormField(
              applicationDetails[3],
              register,
              applicationDetails[3].property_path,
            )}
          </div>
          <div className='grid col-span-2'>
            {generateFormField(
              applicationDetails[4],
              register,
              applicationDetails[4].property_path,
            )}
            <div className='mx-4 relative col-span-12 overflow-hidden'>
              <input
                className='block w-full cursor-pointer bg-primary text-primary-lighter p-4 text-xl font-bold text-center no-underline hover:text-primary-lighter hover:bg-primary-hover transition-colors ease-in-out duration-300'
                value={data.submit.label || 'Submit'}
                type='submit'
                onClick={e => {
                  // @ts-ignore
                  const rect = e.target.getBoundingClientRect();
                  setCoords({
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                  });
                }}
              />
              {isRippling && (
                <span
                  className='ripple'
                  style={{
                    left: coords.x,
                    top: coords.y,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
