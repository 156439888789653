import { t } from 'i18next';
import React, { Fragment } from 'react';

import AnalysisIcon from 'assets/icons/Analysis.svg';
import DeliveryIcon from 'assets/icons/Delivery.svg';
import DesignIcon from 'assets/icons/Design.svg';
import DevelopmentIcon from 'assets/icons/Development.svg';
import RequirementsIcon from 'assets/icons/Requirements.svg';
import TestingIcon from 'assets/icons/Testing.svg';

const title = t('components.developmentProcess.title');

const steps = [
  t('components.developmentProcess.specificationAndConception'),
  t('components.developmentProcess.realization'),
  t('components.developmentProcess.deployment'),
];

const workflowSteps = [
  {
    name: t('components.developmentProcess.requirements'),
    icon: <RequirementsIcon id='requirements-icon' />,
  },
  {
    name: t('components.developmentProcess.analysis'),
    icon: <AnalysisIcon id='analysis-icon' />,
  },
  {
    name: t('components.developmentProcess.design'),
    icon: <DesignIcon id='design-icon' />,
  },
  {
    name: t('components.developmentProcess.development'),
    icon: <DevelopmentIcon id='development-icon' />,
  },
  {
    name: t('components.developmentProcess.testing'),
    icon: <TestingIcon id='testing-icon' />,
  },
  {
    name: t('components.developmentProcess.delivery'),
    icon: <DeliveryIcon id='delivery-icon' />,
  },
];

const DevelopmentProcess = () => {
  const createSteps = () =>
    steps.map(step => (
      <Fragment key={step}>
        <h3 className='flex-1 leading-snug font-light uppercase text-center'>
          {step}
        </h3>
        <hr className='flex-1 border-2 border-primary-text rounded mx-6 last:hidden' />
      </Fragment>
    ));

  const createWorkflowSteps = () =>
    workflowSteps.map(step => (
      <div
        key={step.name}
        className='flex-1 flex flex-col items-center mx-2.5 first:ml-0 last:mr-0'
      >
        {step.icon}
        <p className='font-medium text-center'>{step.name}</p>
      </div>
    ));

  return (
    <div>
      <h3 className='uppercase text-center'>{title}</h3>
      <div className='flex items-center justify-between mt-6 xl:mx-16'>
        {createSteps()}
      </div>
      <div className='flex items-center justify-between mt-10 mb-1'>
        {createWorkflowSteps()}
      </div>
    </div>
  );
};

export default DevelopmentProcess;
