import { z } from 'zod';

import {
  ESuluCardSliderVariants,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBPageCard from './ZSCBPageCard';

const ZSCBCardSlider = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.CARD_SLIDER),
  title: z.string().or(z.null()),
  variant: z.nativeEnum(ESuluCardSliderVariants).or(z.null()),
  cards: z.array(ZSCBPageCard),
});

export default ZSCBCardSlider;
