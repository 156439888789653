import React from 'react';

import ButtonLink, { IButtonLink } from 'components/primitives/ButtonLink';

interface ICardIcon {
  icon?: string;
  title?: string;
  copy: string;
  button?: IButtonLink;
}

const CardIcon = ({ icon, title, copy, button }: ICardIcon) => {
  const bgImageStyle = {
    paddingTop: '56.25%', // aspect ratio 16:9
    backgroundImage: `url(${icon})`,
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className='flex flex-col h-full bg-white shadow-md hover:shadow-card-hover cursor-pointer transition-all ease-in-out duration-300'
      onClick={() => {
        if (!button || 'onClick' in button) {
          return;
        }
        if (button.target && button.target === '_blank') {
          window.open(button.url, button.target);
        } else {
          window.location.href = button.url;
        }
      }}
    >
      <div className='flex-1 px-6 pt-12 pb-9'>
        {icon && (
          <div
            className='bg-center bg-no-repeat bg-contain'
            style={bgImageStyle}
          />
        )}
        {title && <h3 className='text-center mt-5'>{title}</h3>}
        <div
          className='text-center font-light mt-4 text-gray'
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
      {button && <ButtonLink {...button} />}
    </div>
  );
};

CardIcon.defaultProps = {
  icon: undefined,
  title: undefined,
  button: undefined,
};

export default CardIcon;
