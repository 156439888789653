import React, { useEffect, useState } from 'react';

export interface IButtonLink extends React.ComponentPropsWithoutRef<'a'> {
  label: string;
  url: string;
  disabled?: boolean;
  alignCenter?: boolean;
}

const ButtonLink = ({
  label,
  url,
  disabled,
  alignCenter,
  ...rest
}: IButtonLink) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  return (
    <button
      className={`buttonLink relative w-full overflow-hidden${
        alignCenter ? ' alignCenter' : ''
      }`}
      type='button'
      onClick={e => {
        // @ts-ignore
        const rect = e.target.getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
      }}
      disabled={disabled}
    >
      <a
        href={url || '#'}
        className={`block bg-primary text-primary-lighter p-4 border border-primary font-medium text-center no-underline hover:text-primary-lighter hover:bg-primary-hover hover:border-primary-hover transition-colors ease-in-out duration-300 ${
          disabled && 'pointer-events-none'
        }`}
        {...rest}
      >
        {isRippling && (
          <span
            className='ripple'
            style={{
              left: coords.x,
              top: coords.y,
            }}
          />
        )}
        {label || ''}
      </a>
    </button>
  );
};

ButtonLink.defaultProps = {
  disabled: false,
  alignCenter: false,
};

export default ButtonLink;
