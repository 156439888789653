import React from 'react';

import ButtonLink from 'components/primitives/ButtonLink';

import { IPricing, IPricingCost } from 'types/interfaces/IPricing';

const Pricing = ({
  background_variant,
  title,
  description_html,
  button_link,
  open_in_new_tab,
  button_text,
  items,
}: IPricing) => {
  const createCostItems = (costItems: IPricingCost[]) =>
    costItems.map(costItem => (
      <div
        className={`flex-1 flex flex-col justify-center text-center px-6 py-3 border-b-2 lg:border-b-0 lg:border-r-2 last:border-none ${
          background_variant && background_variant.startsWith('primary')
            ? 'border-white/50'
            : 'border-gray/50'
        }`}
      >
        <p className='text-3xl font-bold'>{costItem.numberOfPeople}</p>
        <p className='text-2xl mt-1'>{costItem.price}</p>
      </div>
    ));

  const createItems = () =>
    items.map(item => (
      <div>
        <h2 className='text-center lg:text-left'>{item.headline}</h2>
        <p className='mt-0 text-center lg:text-left'>{item.subline}</p>
        <div className='flex flex-col lg:flex-row mt-8'>
          {createCostItems(item.costs)}
        </div>
      </div>
    ));

  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-y-12 lg:gap-y-0 lg:gap-x-24 lg:px-16'>
      <div className='col-span-1 self-center'>
        <h2>{title}</h2>
        <div
          className='font-light mt-6'
          dangerouslySetInnerHTML={{ __html: description_html }}
        />
        {button_text && button_link && (
          <div className='mt-10'>
            <ButtonLink
              label={button_text}
              url={button_link}
              target={open_in_new_tab ? '_blank' : '_self'}
            />
          </div>
        )}
      </div>
      <div className='lg:col-span-2 flex flex-col gap-12'>{createItems()}</div>
    </div>
  );
};

export default Pricing;
