import { RefObject } from 'react';

export default (
  scrollToElementRef: RefObject<any>,
  isScrollToElementRefInViewport: boolean,
) => {
  if (
    scrollToElementRef &&
    scrollToElementRef.current &&
    !isScrollToElementRefInViewport
  ) {
    scrollToElementRef.current.scrollIntoView({ behavior: 'smooth' });
  }
};
