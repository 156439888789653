import { z } from 'zod';

import {
  ESuluDataTypes,
  ESuluPageJobLevel,
  ESuluPageJobType,
  ESuluPageTypes,
} from 'types/enums/sulu/EBackendData';

import { ZSuluContentBlock } from './IContentBlocks';
import { ZSFSubmit, ZSuluFormInput, ZSuluFormMeta } from './IForm';
import { ZSSAboutCustomer, ZSSPageHeader } from './ISnippets';
import ZSCBImageWithLink from './contentBlocks/ZSCBImageWithLink';
import ZSCBPage from './contentBlocks/ZSCBPage';
import ZSCBText from './contentBlocks/ZSCBText';
import ZSuluMediaVideo from './contentBlocks/ZSuluMediaVideo';

/**
 * MIND: ZSP = Zod Sulu Page
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: In some cases we have an additional zod type "PageOnly".
 * This is necessary as both the blocks and the snippets are located
 * in a nested key of the pages' template.
 * The omit method of zod does not allow omitting nested keys.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

const urls = z.object({
  de: z.string(),
  en: z.string(),
});

const ZSuluPageBaseData = z.object({
  id: z.string().uuid(),
  urls: urls.partial(),
  type: z.literal(ESuluDataTypes.PAGE),
});

const ZSPContentBase = z.object({
  title: z.string(),
  url: z.string().or(z.null()),
});

const ZSPContactFormContent = z.object({
  title: z.string(),
  form: z.array(
    z.object({
      inputs: z.array(ZSuluFormInput),
      meta: z.array(ZSuluFormMeta),
      submit: ZSFSubmit,
    }),
  ),
});

const ZSPApplyFormContent = z.object({
  title: z.string(),
  form: z.array(
    z.object({
      inputs: z.array(ZSuluFormInput),
      meta: z.array(ZSuluFormMeta),
      submit: ZSFSubmit,
    }),
  ),
});

const ZSPContactForm = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluDataTypes.SNIPPET),
  template: z.string(),
  content: ZSPContactFormContent,
});

const ZSPApplyForm = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluDataTypes.SNIPPET),
  template: z.string(),
  content: ZSPApplyFormContent,
});

export type ISuluApplyForm = z.infer<typeof ZSPApplyForm>;
export type ISuluContactForm = z.infer<typeof ZSPContactForm>;

const ZSPDefaultContent = ZSPContentBase.extend({
  header: ZSSPageHeader.or(z.null()),
  content_blocks: z.array(ZSuluContentBlock),
  contact_form: ZSPContactForm.or(z.null()),
});

export const ZSPDefault = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.DEFAULT),
  content: ZSPDefaultContent,
});

export const ZSPDefaultPageOnly = ZSPDefault.merge(
  z.object({
    content: ZSPDefaultContent.omit({ content_blocks: true, header: true }),
  }),
);

const ZSPJobContent = ZSPContentBase.extend({
  header: ZSSPageHeader.or(z.null()),
  job_level: z.nativeEnum(ESuluPageJobLevel).or(z.null()),
  employment_type: z.nativeEnum(ESuluPageJobType).or(z.null()),
  job_name: z.string(),
  intro_html: z.string(),
  text_left_html: z.string(),
  text_right_html: z.string(),
  this_is_us: z.array(ZSCBText),
  and_thats_you: z.array(ZSCBText),
  thats_what_we_offer: z.string(),
  apply_form: ZSPApplyForm,
});

export const ZSPJob = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.JOB),
  content: ZSPJobContent,
});

export const ZSPJobPageOnly = ZSPJob.merge(
  z.object({
    content: ZSPJobContent.omit({
      this_is_us: true,
      and_thats_you: true,
    }),
  }),
);

export const ZSPProject = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.PROJECT),
  content: ZSPContentBase.extend({
    header: ZSSPageHeader.or(z.null()),
    customer_name: z.string(),
    project_name: z.string(),
    intro_html: z.string(),
    intro_media: ZSuluMediaVideo.or(z.null()),
    content_blocks: z.array(ZSuluContentBlock),
    initiation_date: z.string(),
    runtime_weeks: z.string(),
    core_technologies: z.string(),
    text_left_html: z.string(),
    text_right_html: z.string(),
    project_media: ZSuluMediaVideo.or(z.null()),
    about: ZSSAboutCustomer,
    more_customers: z.array(ZSCBImageWithLink),
  }),
});

export const ZSPProjectsArticles = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.PROJECTS_ARTICLES),
  content: ZSPContentBase.extend({
    header: ZSSPageHeader.or(z.null()),
    content_blocks: z.array(ZSCBPage),
    contact_form: ZSPContactForm.or(z.null()),
  }),
});

export const ZSuluPage = z.union([
  ZSPDefault,
  ZSPJob,
  ZSPProject,
  ZSPProjectsArticles,
]);

export type ISPDefault = z.infer<typeof ZSPDefault>;
export type ISPJob = z.infer<typeof ZSPJob>;
export type ISPProject = z.infer<typeof ZSPProject>;
export type ISPProjectsArticles = z.infer<typeof ZSPProjectsArticles>;
export type ISuluPage = z.infer<typeof ZSuluPage>;
