import { z } from 'zod';

import {
  ESuluDataTypes,
  ESuluFormFields,
  ESuluFormMetaFields,
  ESuluFormWidths,
} from 'types/enums/sulu/EBackendData';

// @Todo Short title, Example, Default missing for all fields!

const ZSFBase = z.object({
  label: z.string().or(z.null()),
  attr: z.object({
    width: z.nativeEnum(ESuluFormWidths),
    widthNumber: z.number().min(1).max(12),
    lastWidth: z.boolean(), // @Todo what is this?
    placeholder: z.string().optional(),
  }),
  required: z.boolean().or(z.null()),
  choices: z.array(z.string()).default([]),
});

const ZSFText = ZSFBase.extend({
  type: z.literal(ESuluFormFields.TEXT),
  property_path: z.literal(ESuluFormFields.TEXT),
});

const ZSFEmail = ZSFBase.extend({
  type: z.literal(ESuluFormFields.EMAIL),
  property_path: z.literal(ESuluFormFields.EMAIL),
});

const ZSFCheckboxMultiple = ZSFBase.extend({
  type: z.literal(ESuluFormFields.CHECKBOX_MULTIPLE),
  property_path: z.literal(ESuluFormFields.CHECKBOX_MULTIPLE),
  choices: z.array(z.string()),
});

const ZSFTextarea = ZSFBase.extend({
  type: z.literal(ESuluFormFields.TEXTAREA),
  property_path: z.literal(ESuluFormFields.TEXTAREA),
});

const ZSFDropdown = ZSFBase.extend({
  type: z.literal(ESuluFormFields.DROPDOWN),
  property_path: z.literal(ESuluFormFields.DROPDOWN),
}); // @Todo options missing

const ZSFTitle = ZSFBase.extend({
  type: z.literal(ESuluFormFields.TITLE),
  property_path: z.literal(ESuluFormFields.TITLE),
});

const ZSFFirstName = ZSFBase.extend({
  type: z.literal(ESuluFormFields.FIRST_NAME),
  property_path: z.literal(ESuluFormFields.FIRST_NAME),
});

const ZSFLastName = ZSFBase.extend({
  type: z.literal(ESuluFormFields.LAST_NAME),
  property_path: z.literal(ESuluFormFields.LAST_NAME),
});

const ZSFStreet = ZSFBase.extend({
  type: z.literal(ESuluFormFields.STREET),
  property_path: z.literal(ESuluFormFields.STREET),
});

const ZSFSalutation = ZSFBase.extend({
  type: z.literal(ESuluFormFields.SALUTATION),
  property_path: z.literal(ESuluFormFields.SALUTATION),
});

const ZSFCity = ZSFBase.extend({
  type: z.literal(ESuluFormFields.CITY),
  property_path: z.literal(ESuluFormFields.CITY),
});

const ZSFState = ZSFBase.extend({
  type: z.literal(ESuluFormFields.STATE),
  property_path: z.literal(ESuluFormFields.STATE),
});

const ZSFAttachment = ZSFBase.extend({
  type: z.literal(ESuluFormFields.ATTACHMENT),
  property_path: z.string(), // the path can be "attachment", but also "attachment1" etc!,
}); // @Todo Restrict file types, Maximum amount missing!

const ZSFCheckbox = ZSFBase.extend({
  type: z.literal(ESuluFormFields.CHECKBOX),
  property_path: z.literal(ESuluFormFields.CHECKBOX),
});

/* META TYPES */
const ZSFMetaLocale = z.object({
  property_path: z.literal(ESuluFormMetaFields.LOCALE),
  data: z.string(),
});

const ZSFMetaType = z.object({
  property_path: z.literal(ESuluFormMetaFields.TYPE),
  data: z.nativeEnum(ESuluDataTypes),
});

const ZSFMetaTypeID = z.object({
  property_path: z.literal(ESuluFormMetaFields.TYPE_ID),
  data: z.string(),
});

const ZSFMetaFormID = z.object({
  property_path: z.literal(ESuluFormMetaFields.FORM_ID),
  data: z.number(),
});

const ZSFMetaFormName = z.object({
  property_path: z.literal(ESuluFormMetaFields.FORM_NAME),
  data: z.string(),
});

const ZSFMetaChecksum = z.object({
  property_path: z.literal(ESuluFormMetaFields.CHECKSUM),
  data: z.string(),
});

/* SUBMIT */
export const ZSFSubmit = ZSFBase.extend({
  property_path: z.literal(ESuluFormFields.SUBMIT),
}).omit({
  required: true,
});

export const ZSuluFormInput = z.union([
  ZSFEmail,
  ZSFText,
  ZSFCheckboxMultiple,
  ZSFTextarea,
  ZSFDropdown,
  ZSFTitle,
  ZSFFirstName,
  ZSFLastName,
  ZSFStreet,
  ZSFCity,
  ZSFState,
  ZSFSalutation,
  ZSFAttachment,
  ZSFCheckbox,
]);
export const ZSuluFormMeta = z.union([
  ZSFMetaLocale,
  ZSFMetaType,
  ZSFMetaTypeID,
  ZSFMetaFormID,
  ZSFMetaFormName,
  ZSFMetaChecksum,
]);

export type ISuluFormInput = z.infer<typeof ZSuluFormInput>;
