import React from 'react';

import EasyAssessor from 'components/static/Pricing/EasyAssessor';
import EasyCRM from 'components/static/Pricing/EasyCRM';
import Whistleblower from 'components/static/Pricing/Whistleblower';

import { ESuluContentBlockSolutionType } from 'types/enums/sulu/EBackendData';
import { ISCBPricing } from 'types/interfaces/sulu/IContentBlocks';

const PricingWrapper = (props: ISCBPricing) => {
  const { solution_type: solutionType } = props;

  switch (solutionType) {
    case ESuluContentBlockSolutionType.WHISTLEBLOWER:
      return <Whistleblower {...props} />;
    case ESuluContentBlockSolutionType.EASY_CRM:
      return <EasyCRM {...props} />;
    case ESuluContentBlockSolutionType.EASY_ASSESSOR:
      return <EasyAssessor {...props} />;
    default:
      return <div>No pricing component found!</div>;
  }
};

export default PricingWrapper;
