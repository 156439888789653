import { z } from 'zod';

import {
  ESuluContentBlockSolutionType,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBPricing = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.PRICING),
  solution_type: z.nativeEnum(ESuluContentBlockSolutionType).or(z.null()),
  title: z.string(),
  description_html: z.string(),
  button_link: z.string().or(z.null()),
  open_in_new_tab: z.boolean(),
  button_text: z.string(),
});

export default ZSCBPricing;
