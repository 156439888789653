import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from 'components/primitives/ButtonLink';

export default () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='relative theme-primary page-header z-[-1]'>
        <div className='pt-14'>
          <div className='min-h-full px-10 lg:px-20 text-center flex flex-col justify-between overflow-hidden' />
        </div>
      </div>
      <div className='blockOuter theme-default text'>
        <div className='blockInner'>
          <div className='grid md:grid-cols-2'>
            <div className=''>
              <img
                src='/assets/errorpage.png'
                className='w-full'
                alt={t('altTexts.error404')}
              />
            </div>
            <div className='md:pl-20'>
              <h2 className='text-primary font-bold text-center pt-10 md:pt-0'>
                Oops!
              </h2>
              <h2 className='text-center'>
                We’re sorry, seems like the page got lost in space. Find your
                way back home.
              </h2>
              <br />
              <br />
              <ButtonLink label='Back to home!' url='/' alignCenter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
