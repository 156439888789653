import { Options } from '@splidejs/react-splide';
import { nanoid } from 'nanoid';
import React from 'react';

import ButtonLink from 'components/primitives/ButtonLink';
import Slider from 'components/primitives/Slider';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import {
  ESuluCardSliderVariants,
  ESuluContentBlockBackgroundVariants,
} from 'types/enums/sulu/EBackendData';
import { ISCBIconCardBlock } from 'types/interfaces/sulu/IContentBlocks';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

import './IconCardBlock.css';

const IconCardBlock = ({
  background_variant,
  variant,
  title,
  icon_blocks,
}: ISCBIconCardBlock) => {
  const getPerPageOption = (): number => {
    const numberOfCards = icon_blocks.length;

    if (variant === ESuluCardSliderVariants.DOTS) {
      return numberOfCards > 3 ? 3 : numberOfCards;
    }

    return numberOfCards > 4 ? 4 : numberOfCards;
  };

  const cardIconSliderOptions: Options = {
    perPage: getPerPageOption(),
    breakpoints: {
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) - 1]: {
        perPage: 1,
        drag: true,
      },
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) - 1]: {
        perPage: 2,
        drag: true,
      },
      [getTailwindBreakpointAsNumber(ETailwindBreakpoint.xl) - 1]: {
        perPage: 3,
        drag: true,
      },
    },
  };

  const hasLightPagination =
    background_variant === ESuluContentBlockBackgroundVariants.PRIMARY ||
    background_variant === ESuluContentBlockBackgroundVariants.PRIMARY_GRADIENT;

  return (
    <div>
      {title && <h2 className='text-center'>{title}</h2>}
      <Slider
        options={cardIconSliderOptions}
        hasLightPagination={hasLightPagination}
      >
        {icon_blocks.map(icon_block => (
          <div key={nanoid()} className='flex flex-col h-full text-center'>
            <div className='w-48 mx-auto'>
              <img
                alt={
                  icon_block.icon?.description || icon_block.icon?.title || ''
                }
                src={icon_block.icon?.url}
              />
            </div>
            {icon_block.title && <h3>{icon_block.title || ''}</h3>}
            <p
              className='flex-1 w-3/4 mx-auto text-gray'
              dangerouslySetInnerHTML={{
                __html: icon_block.description_html || '',
              }}
            />
            {icon_block.button_text && icon_block.button_link && (
              <div className='mt-3'>
                <ButtonLink
                  label={icon_block.button_text}
                  url={icon_block.button_link}
                  target={icon_block.open_in_new_tab ? '_blank' : '_self'}
                />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default IconCardBlock;
