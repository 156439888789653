// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React, { Key } from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import {
  ImageWithLink,
  SimpleCard,
  SplitContent,
  Text,
  TextMedia,
  TextRow,
  Wysiwyg,
} from 'components/blocks';
import Banner from 'components/blocks/Banner';
import CardSlider from 'components/blocks/CardSlider';
import IFrame from 'components/blocks/IFrame';
import IconCardBlock from 'components/blocks/IconCardBlock';
import ImageOnly from 'components/blocks/ImageOnly';
import MultipleImageText from 'components/blocks/MultipleImageText';
import PricingWrapper from 'components/blocks/PricingWrapper';
import SliderSelector from 'components/blocks/SliderSelector';
import BlockContainer from 'components/blocks/_blockContainer';
import DevelopmentProcess from 'components/static/DevelopmentProcess';
import TeamAndLocations from 'components/static/TeamAndLocations';
import Teamwork from 'components/static/Teamwork';
import Timeline from 'components/static/Timeline';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';
import { ISuluContentBlock } from 'types/interfaces/sulu/IContentBlocks';
import ZSCBBanner from 'types/interfaces/sulu/contentBlocks/ZSCBBanner';
import ZSCBCard from 'types/interfaces/sulu/contentBlocks/ZSCBCard';
import ZSCBCardSlider from 'types/interfaces/sulu/contentBlocks/ZSCBCardSlider';
import ZSCBDevelopmentProcess from 'types/interfaces/sulu/contentBlocks/ZSCBDevelopmentProcess';
import ZSCBIFrame from 'types/interfaces/sulu/contentBlocks/ZSCBIFrame';
import ZSCBIconCardBlock from 'types/interfaces/sulu/contentBlocks/ZSCBIconCardBlock';
import ZSCBImageOnly from 'types/interfaces/sulu/contentBlocks/ZSCBImageOnly';
import ZSCBImageWithLink from 'types/interfaces/sulu/contentBlocks/ZSCBImageWithLink';
import ZSCBMultipleImageText from 'types/interfaces/sulu/contentBlocks/ZSCBMultipleImageText';
import ZSCBPricing from 'types/interfaces/sulu/contentBlocks/ZSCBPricing';
import ZSCBSliderSelector from 'types/interfaces/sulu/contentBlocks/ZSCBSliderSelector';
import ZSCBSplitContent from 'types/interfaces/sulu/contentBlocks/ZSCBSplitContent';
import ZSCBTeamAndLocations from 'types/interfaces/sulu/contentBlocks/ZSCBTeamAndLocations';
import ZSCBTeamwork from 'types/interfaces/sulu/contentBlocks/ZSCBTeamwork';
import ZSCBText from 'types/interfaces/sulu/contentBlocks/ZSCBText';
import ZSCBTextMedia from 'types/interfaces/sulu/contentBlocks/ZSCBTextMedia';
import ZSCBTextRow from 'types/interfaces/sulu/contentBlocks/ZSCBTextRow';
import ZSCBTimeline from 'types/interfaces/sulu/contentBlocks/ZSCBTimeline';
import ZSCBWysiwyg from 'types/interfaces/sulu/contentBlocks/ZSCBWysiwyg';

const parseBlockData = (
  blockData: ISuluContentBlock,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: blockData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const blockDataJS = toJS(blockData);
  const zodParsed = zodType.safeParse(blockDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `block-${blockData.type}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (blockData: ISuluContentBlock, key: Key): JSX.Element => {
  const { type } = blockData;
  let backgroundVariant;
  if ('background_variant' in blockData) {
    ({ background_variant: backgroundVariant } = blockData);
  }
  let blockToRender = <h2>No component found!</h2>;
  let outerClass = '';
  let innerClass = '';

  switch (type) {
    case ESuluContentBlockTypes.BANNER:
      outerClass = '!py-0';
      innerClass = 'fullWidth';
      if (parseBlockData(blockData, ZSCBBanner)) {
        blockToRender = <Banner {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TEXT:
      if (parseBlockData(blockData, ZSCBText)) {
        const { has_narrow_width: hasNarrowWidth } = blockData;
        if (hasNarrowWidth) {
          innerClass = 'narrowWidth';
        }
        blockToRender = <Text {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TEXT_MEDIA:
      if (parseBlockData(blockData, ZSCBTextMedia)) {
        const { has_narrow_width: hasNarrowWidth } = blockData;
        if (hasNarrowWidth) {
          innerClass = 'narrowWidth';
        }
        blockToRender = <TextMedia {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.SPLIT_CONTENT:
      if (parseBlockData(blockData, ZSCBSplitContent)) {
        const { title } = blockData;
        outerClass = `!p-0${title ? ' has-title' : ''}`;
        innerClass = '!p-0 !max-w-none';
        blockToRender = <SplitContent {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.CARD_SLIDER:
      if (parseBlockData(blockData, ZSCBCardSlider)) {
        const { title } = blockData;
        outerClass = title ? 'has-title' : '';
        blockToRender = <CardSlider {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.CARD:
      if (parseBlockData(blockData, ZSCBCard)) {
        const { title } = blockData;
        outerClass = title ? 'has-title' : '';
        blockToRender = <SimpleCard {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TEXT_ROW:
      if (parseBlockData(blockData, ZSCBTextRow)) {
        blockToRender = <TextRow {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.MULTIPLE_IMAGE_TEXT:
      outerClass = '!p-0';
      innerClass = '!p-0 !max-w-none';
      if (parseBlockData(blockData, ZSCBMultipleImageText)) {
        blockToRender = <MultipleImageText {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.IMAGE_WITH_LINK:
      if (parseBlockData(blockData, ZSCBImageWithLink)) {
        const { has_fullwidth_image: hasFullwidthImage } = blockData;
        if (hasFullwidthImage) {
          innerClass = 'has-fullwidth-image';
        }
        blockToRender = <ImageWithLink {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.WYSIWYG:
      if (parseBlockData(blockData, ZSCBWysiwyg)) {
        blockToRender = <Wysiwyg {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.SLIDER_SELECTOR:
      if (parseBlockData(blockData, ZSCBSliderSelector)) {
        const { title } = blockData;
        outerClass = title ? 'has-title' : '';
        blockToRender = <SliderSelector {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.ICON_CARD_BLOCK:
      if (parseBlockData(blockData, ZSCBIconCardBlock)) {
        const { title } = blockData;
        outerClass = title ? 'has-title' : '';
        blockToRender = <IconCardBlock {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.DEVELOPMENT_PROCESS:
      outerClass = 'hidden lg:block';
      if (parseBlockData(blockData, ZSCBDevelopmentProcess)) {
        blockToRender = <DevelopmentProcess key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TEAM_AND_LOCATIONS:
      innerClass = 'narrowWidth';
      if (parseBlockData(blockData, ZSCBTeamAndLocations)) {
        blockToRender = <TeamAndLocations key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TEAMWORK:
      if (parseBlockData(blockData, ZSCBTeamwork)) {
        blockToRender = <Teamwork key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TIMELINE:
      if (parseBlockData(blockData, ZSCBTimeline)) {
        blockToRender = <Timeline key={key} />;
      }
      break;
    case ESuluContentBlockTypes.IFRAME:
      if (parseBlockData(blockData, ZSCBIFrame)) {
        const {
          has_narrow_width: hasNarrowWidth,
          has_no_top_padding: hasNoTopPadding,
        } = blockData;
        if (hasNarrowWidth) {
          innerClass = 'narrowWidth';
        }
        outerClass = hasNoTopPadding ? '!pt-0' : '';
        blockToRender = <IFrame {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.IMAGE_ONLY:
      if (parseBlockData(blockData, ZSCBImageOnly)) {
        const { has_narrow_width: hasNarrowWidth, is_centered: isCentered } =
          blockData;
        const innerClassArray = [];
        if (hasNarrowWidth) {
          innerClassArray.push('narrowWidth');
        }
        if (isCentered) {
          innerClassArray.push(` flex justify-center`);
        }
        innerClass = innerClassArray.join(' ');
        blockToRender = <ImageOnly {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.PRICING:
      if (parseBlockData(blockData, ZSCBPricing)) {
        blockToRender = <PricingWrapper {...blockData} key={key} />;
      }
      break;
    default: {
      return <div>No component found!</div>;
    }
  }

  return (
    <BlockContainer
      key={key}
      backgroundVariant={backgroundVariant}
      outerClasses={`${type} ${outerClass}`}
      innerClasses={`${innerClass}`}
    >
      {blockToRender}
    </BlockContainer>
  );
};
