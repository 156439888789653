import React from 'react';

import { ISuluFormInput } from '../../../types/interfaces/sulu/IForm';

export interface IDropdownComponent {
  data: ISuluFormInput;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

const DropdownComponent = ({ data, setSelectedOption }: IDropdownComponent) => {
  const createOptions = () =>
    data.choices.map((choice: string) => (
      <option key={choice} value={choice}>
        {choice}
      </option>
    ));

  return (
    <select
      className='input mb-2 bg-primary-lighter h-9 w-full pl-5'
      name={data.label || ''}
      onChange={e => {
        setSelectedOption(e.target.selectedOptions[0].value);
      }}
    >
      {createOptions()}
    </select>
  );
};

export default DropdownComponent;
