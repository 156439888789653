import React from 'react';

import { Card } from 'components/blocks';
import BlockContainer from 'components/blocks/_blockContainer';

import { ISSAboutCustomer } from 'types/interfaces/sulu/ISnippets';

import {
  ISCBCard,
  ISCBContent,
} from '../../types/interfaces/sulu/IContentBlocks';

export default ({ content }: ISSAboutCustomer) => {
  const {
    title,
    subtitle,
    description,
    background_variant: backgroundVariant,
    card,
  } = content;

  const getCardData = (c: ISCBCard): ISCBContent => ({
    title,
    url: c.button_link,
    open_in_new_tab: c.open_in_new_tab,
    card_image: c.image,
    card_title: c.title || '',
    card_description_html: c.description_html || '',
    card_button_text: c.button_text || '',
  });

  return (
    <BlockContainer backgroundVariant={backgroundVariant}>
      <div className='grid grid-cols-1 lg:grid-cols-2'>
        <div className='lg:px-20'>
          <h1 className='pb-0'>{title}</h1>
          <p className='font-bold'>{subtitle}</p>
          <div
            className='mt-9'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className='mt-10 lg:mt-0 lg:px-20'>
          {card.map(cardItem => (
            <Card key={cardItem.title} {...getCardData(cardItem)} />
          ))}
        </div>
      </div>
    </BlockContainer>
  );
};
