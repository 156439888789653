import React from 'react';

import { ISCBImageWithLink } from 'types/interfaces/sulu/IContentBlocks';

const LogoWall = ({ media, link }: ISCBImageWithLink) => (
  <div
    className='w-44 h-44 bg-cover bg-center bg-no-repeat'
    style={{
      backgroundImage: `url(${media.url})`,
    }}
  >
    <a className='block w-full h-full opacity-0' href={link || '#'}>
      {link}
    </a>
  </div>
);

export default LogoWall;
