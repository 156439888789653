import { nanoid } from 'nanoid';
import React from 'react';

import Slider from 'components/primitives/Slider';

export interface IInfoBannerSlider {
  title?: string;
  items: {
    icon: JSX.Element;
    title: string;
    copy: string;
  }[];
}

const InfoBannerSlider = ({ title, items }: IInfoBannerSlider) => (
  <div>
    {title && <h3 className='text-center'>{title}</h3>}
    <Slider options={{ perPage: 3, gap: '3rem' }} hasLightPagination>
      {items.map(item => (
        <div key={nanoid()} className='text-center mb-1'>
          <div className='w-48 mx-auto'>{item.icon}</div>
          <h3>{item.title}</h3>
          <p className='w-3/4 lg:w-1/2 mx-auto'>{item.copy}</p>
        </div>
      ))}
    </Slider>
  </div>
);

InfoBannerSlider.defaultProps = {
  title: '',
};

export default InfoBannerSlider;
