import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowScroll } from 'react-use';

import LogoWhite from 'assets/logos/logo_white.svg';

import BlockContainer from 'components/blocks/_blockContainer';
import LanguageSwitch from 'components/static/LanguageSwitch';

import { INavigation } from 'types/interfaces/sulu/INavigation';

import removeTagsFromString from 'utils/removeTagsFromString';
import getCurrentLanguage from 'utils/sulu/getCurrentLanguage';

interface IMenu {
  menuItems: INavigation[];
}

const isActive = (menuItem: INavigation): boolean =>
  window.location.pathname === `/${menuItem.locale}${menuItem.url}`;

export default ({ menuItems }: IMenu) => {
  const { t } = useTranslation();
  const { y: windowScrollY } = useWindowScroll();
  const [activeMenuItem, setActiveMenuItem] = useState<INavigation>();
  const [displaySubmenu, setDisplaySubmenu] = useState(false);
  const [mouseIntoSubmenu, setMouseIntoSubmenu] = useState(false);

  const navigateToHome = () => {
    window.location.href = `/${getCurrentLanguage}`;
  };

  const menuClassName =
    'flex items-center justify-center text-center hover:text-white font-raleway font-medium text-sm leading-6 py-4 uppercase';

  const getBackgroundColor = () => {
    if (windowScrollY <= 10 && !displaySubmenu) {
      return 'bg-transparent';
    }

    let bgColor = 'backdrop-blur-sm bg-primary/90';

    if (windowScrollY > 10 && !displaySubmenu) {
      bgColor += ' shadow-md';
    }

    return bgColor;
  };

  useEffect(() => {
    if (windowScrollY > 10) {
      setDisplaySubmenu(false);
    }
  }, [windowScrollY]);

  return (
    <div className='fixed top-0 inset-x-0 z-[9999]'>
      <BlockContainer outerClasses='!py-0' innerClasses='!px-0'>
        <div>
          <div
            className={`relative flex items-center justify-between px-10 z-50 transition-colors ease-in-out duration-300 ${getBackgroundColor()}`}
          >
            <div className='mr-10'>
              <LogoWhite
                className='h-6 cursor-pointer'
                onClick={navigateToHome}
              />
            </div>
            <div className='flex gap-8'>
              <a
                className={`${menuClassName} ${
                  window.location.pathname === `/${getCurrentLanguage}`
                    ? 'text-white'
                    : 'text-white/50'
                }`}
                href={`/${getCurrentLanguage}`}
              >
                {t('navigation.home')}
              </a>
              {menuItems.map(menuItem => (
                <div
                  key={menuItem.id}
                  onMouseEnter={() => {
                    if (menuItem.children.length > 0) {
                      setDisplaySubmenu(true);
                      setActiveMenuItem(menuItem);
                    } else {
                      setDisplaySubmenu(false);
                    }
                  }}
                >
                  <a
                    className={`${menuClassName} ${
                      isActive(menuItem) ? 'text-white' : 'text-white/50'
                    }`}
                    href={`/${menuItem.locale}${menuItem.url}`}
                    onClick={() => setDisplaySubmenu(false)}
                  >
                    {menuItem.title}
                  </a>
                </div>
              ))}
              <LanguageSwitch />
            </div>
          </div>
          <div
            className={`absolute grid grid-cols-3 w-full min-h-[300px] px-10 py-2 backdrop-blur-sm bg-primary/90 z-40 transition-all ease-in-out duration-300
             ${
               displaySubmenu
                 ? 'top-full opacity-100 visible'
                 : '-top-[100vh] opacity-0 invisible'
             }`}
          >
            {activeMenuItem && (
              <div className='flex items-center'>
                <div>
                  <h3 className='uppercase text-white self-end'>
                    {activeMenuItem.excerpt.title}
                  </h3>
                  <p className='text-white mt-4 mr-20 self-start'>
                    {removeTagsFromString(
                      activeMenuItem.excerpt.description || '',
                    )}
                  </p>
                </div>
              </div>
            )}
            <div
              className={`grid col-span-2 grid-rows-3 cursor-pointer ${
                activeMenuItem && activeMenuItem.children.length > 3
                  ? 'grid-cols-2'
                  : 'grid-cols-1'
              } grid-flow-row`}
              onMouseEnter={() => setMouseIntoSubmenu(true)}
              onMouseLeave={() => setMouseIntoSubmenu(false)}
            >
              {activeMenuItem &&
                activeMenuItem.children.map(item => (
                  <a
                    key={item.id}
                    className={`flex justify-between gap-6 pl-0 p-4 mr-6 transition-opacity ease-in-out duration-300 ${
                      mouseIntoSubmenu ? 'opacity-50' : 'opacity-100'
                    } hover:opacity-100 border-b-2 [&:nth-child(5)]:border-b-transparent last:border-b-transparent`}
                    href={`/${item.locale}${item.url}`}
                    onClick={() => setDisplaySubmenu(false)}
                  >
                    <div className='flex-1 overflow-hidden'>
                      <h3 className='text-white'>{item.excerpt.title}</h3>
                      <p className='text-white mt-0 truncate'>
                        {removeTagsFromString(item.excerpt.description)}
                      </p>
                    </div>
                    <p className='text-white self-end'>{item.excerpt.more}</p>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </BlockContainer>
      {/* backdrop */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`fixed z-30 top-0 left-0 w-full h-full bg-transparent ease-in-out duration-300 ${
          displaySubmenu ? 'visible' : 'invisible'
        }`}
        onClick={() => setDisplaySubmenu(false)}
        onMouseOver={() => setDisplaySubmenu(false)}
      />
    </div>
  );
};
