import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { INavigation } from 'types/interfaces/sulu/INavigation';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';
import getNavigation from 'utils/sulu/getNavigation';

import DesktopMenu from './DesktopMenu';
import SidebarMenu from './SidebarMenu';

export default () => {
  const [navigation, setNavigation] = useState<INavigation[]>([]);
  useEffect(() => {
    getNavigation().then(res => setNavigation(res));
  }, []);

  const { width } = useWindowSize();

  return width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) ? (
    <SidebarMenu menuItems={navigation} />
  ) : (
    <DesktopMenu menuItems={navigation} />
  );
};
