import React from 'react';

import LocationIcon from 'assets/icons/Location.svg';

export interface ILocation {
  name: string;
  address: string;
}

export interface ILocations {
  title: string;
  locations: ILocation[];
  note: string;
}

const Locations = ({ title, locations, note }: ILocations) => {
  const createLocations = () =>
    locations.map(location => (
      <div
        key={`${location.name}: ${location.address}`}
        className='flex items-center mb-8'
      >
        <LocationIcon className='w-9 mr-5' />
        <div className='flex-1'>
          <h3 className='uppercase font-light'>{location.name}</h3>
          <p className='font-light mt-1'>{location.address}</p>
        </div>
      </div>
    ));

  return (
    <div>
      <h3 className='uppercase mb-9'>{title}</h3>
      {createLocations()}
      <h3 className='font-light'>{note}</h3>
    </div>
  );
};

export default Locations;
