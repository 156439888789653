import { z } from 'zod';

import {
  ESuluContentBlockSplitVariants,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBCard from './ZSCBCard';
import ZSCBImageWithLink from './ZSCBImageWithLink';
import ZSCBMultipleTextRows from './ZSCBMultipleTextRows';
import ZSCBPageCard from './ZSCBPageCard';
import ZSCBText from './ZSCBText';

const ZSCBSplitCol = z.union([
  ZSCBMultipleTextRows,
  ZSCBPageCard,
  ZSCBCard,
  ZSCBText,
  ZSCBImageWithLink,
]);

const ZSCBSplitContent = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.SPLIT_CONTENT),
  title: z.string().nullish(),
  variant: z.nativeEnum(ESuluContentBlockSplitVariants),
  left: z.array(ZSCBSplitCol),
  right: z.array(ZSCBSplitCol),
});

export default ZSCBSplitContent;
