import React from 'react';

import { ESuluContentBlockTextMediaOrientations } from 'types/enums/sulu/EBackendData';
import { ISCBTextMedia } from 'types/interfaces/sulu/IContentBlocks';

const TextMedia = ({
  media,
  title,
  link,
  text_html,
  media_orientation,
  has_centered_title,
  text_html_fullwidth_default,
}: ISCBTextMedia) => {
  const getOrientation = () => {
    switch (media_orientation) {
      case ESuluContentBlockTextMediaOrientations.TEXT_MEDIA:
        return 'md:order-2';
      case ESuluContentBlockTextMediaOrientations.MEDIA_TEXT:
        return '';
      case ESuluContentBlockTextMediaOrientations.DEFAULT:
      default:
        return 'md:col-span-2';
    }
  };
  return (
    <div className='grid grid-cols-1 md:grid-cols-2'>
      {media && (
        <div className={`${getOrientation()}`}>
          {link ? (
            <a href={link}>
              <img
                className='m-auto'
                src={media.url}
                alt={media.description || media.title}
              />
            </a>
          ) : (
            <img
              className='m-auto'
              src={media.url}
              alt={media.description || media.title}
            />
          )}
        </div>
      )}
      <div
        className={`${
          text_html_fullwidth_default &&
          media_orientation === ESuluContentBlockTextMediaOrientations.DEFAULT
            ? 'md:col-span-2'
            : ''
        } flex flex-col justify-center px-8 py-12 md:p-16`}
      >
        <h2
          className={`font-light uppercase ${
            has_centered_title ? 'text-center' : ''
          }`}
        >
          {title}
        </h2>
        <div
          className='text-2xl md:text-4xl font-light mt-4'
          dangerouslySetInnerHTML={{ __html: text_html }}
        />
      </div>
    </div>
  );
};

export default TextMedia;
