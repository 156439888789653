import { z } from 'zod';

import { ESuluMediaTypes } from 'types/enums/sulu/EBackendData';

const ZSuluMedia = z.object({
  id: z.number(),
  locale: z.string(),
  collection: z.number(),
  size: z.number(),
  mimeType: z.string(),
  title: z.string(),
  description: z.string().optional(),
  name: z.string(),
  type: z.object({
    name: z.nativeEnum(ESuluMediaTypes),
    id: z.number(),
  }),
  tags: z.array(z.string()),
  url: z.string(),
  changed: z.string(),
  changer: z.string(),
  created: z.string(),
  creator: z.string(),
  properties: z.object({
    width: z.number(),
    height: z.number(),
  }),
  categories: z.array(z.string()),
  formatUri: z.string(),
});

export default ZSuluMedia;
export type ISuluMedia = z.infer<typeof ZSuluMedia>;
