import { nanoid } from 'nanoid';
import React from 'react';

import { ISCBImageWithLink } from 'types/interfaces/sulu/IContentBlocks';

import LogoWallItem from './LogoWallItem';

interface ILogoWall {
  title?: string;
  logoWallItems: ISCBImageWithLink[];
}

const LogoWall = ({ title, logoWallItems }: ILogoWall) => {
  const createLogoWallItems = () =>
    logoWallItems.map(logoWallItem => (
      <LogoWallItem key={nanoid()} {...logoWallItem} />
    ));

  return (
    <>
      {title && <h3 className='text-center uppercase mb-8'>{title}</h3>}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-items-center gap-8'>
        {createLogoWallItems()}
      </div>
    </>
  );
};

LogoWall.defaultProps = {
  title: undefined,
};

export default LogoWall;
