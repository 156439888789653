import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBIFrame = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.IFRAME),
  has_narrow_width: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  url: z.string(),
  has_no_top_padding: z.boolean().or(z.null()),
});

export default ZSCBIFrame;
