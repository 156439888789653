import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPricing } from 'types/interfaces/IPricing';
import { ISCBPricing } from 'types/interfaces/sulu/IContentBlocks';

import Pricing from './Pricing';

const Whistleblower = (props: ISCBPricing) => {
  const { t } = useTranslation();

  const pricingData: IPricing = {
    ...props,
    items: [
      {
        headline: t('components.pricing.whistleblower.company.headline'),
        subline: t('components.pricing.whistleblower.company.subline'),
        costs: [
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.company.costs.item1.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.company.costs.item1.price',
            ),
          },
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.company.costs.item2.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.company.costs.item2.price',
            ),
          },
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.company.costs.item3.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.company.costs.item3.price',
            ),
          },
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.company.costs.item4.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.company.costs.item4.price',
            ),
          },
        ],
      },
      {
        headline: t('components.pricing.whistleblower.commune.headline'),
        subline: t('components.pricing.whistleblower.commune.subline'),
        costs: [
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.commune.costs.item1.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.commune.costs.item1.price',
            ),
          },
          {
            numberOfPeople: t(
              'components.pricing.whistleblower.commune.costs.item2.numberOfPeople',
            ),
            price: t(
              'components.pricing.whistleblower.commune.costs.item2.price',
            ),
          },
        ],
      },
    ],
  };

  return <Pricing {...pricingData} />;
};

export default Whistleblower;
