const Color = require('color');

const getThemeVariations = color => {
  const baseColor = Color(color);
  return {
    DEFAULT: baseColor.hsl().string(),
    dark: baseColor.darken(0.7).hsl().string(),
    light: '#e1f2f4',
    lighter: '#f7fbfc',
    lightest: baseColor.lightness(100).hsl().string(), // always white!
    disabled: baseColor.lighten(0.7).desaturate(0.5).hsl().string(),
    hover: baseColor.lighten(0.25).hsl().string(),
  };
};

const hiveBlue = Color('hsl(196, 100%, 43%)');
const hiveBlueHover = hiveBlue.lighten(0.25);
const intrexxYellow = Color('hsl(75, 100%, 60%)');
const intrexxYellowHover = intrexxYellow.lighten(0.25);

const beColors = {
  primary: {
    ...getThemeVariations('hsl(186, 95%, 32%)'),
    text: 'hsl(198, 8%, 25%)', // #3A4144, hwb(198, 23%, 73%)
    'text-light': 'hsl(0, 0%, 80%)', // #AAAAAA, hwb(0, 67%, 33%)
  },
  darkblue: {
    DEFAULT: '#00436E',
  },
  magenta: {
    DEFAULT: '#E20D77',
  },
  gray: {
    DEFAULT: 'hsl(198, 8%, 25%)', // #3A4144, hwb(198, 23%, 73%)
    light: 'hsl(0, 0%, 80%)', // #AAAAAA, hwb(0, 67%, 33%)
    lighter: 'hsl(0, 0%, 96%)', // #F6F6F6
  },
  black: {
    DEFAULT: 'hsla(0, 0%, 0%, 0.16)',
  },
  fullblack: {
    DEFAULT: 'hsla(0, 0%, 0%)',
  },
  'hive-blue': {
    DEFAULT: hiveBlue.hsl().string(), // #00A1DB, hwb(196, 0%, 14%)
    hover: hiveBlueHover.hsl().string(),
  },
  'intrexx-yellow': {
    DEFAULT: intrexxYellow.hsl().string(), // #CCFF33, hwb(75, 20%, 0%)
    hover: intrexxYellowHover.hsl().string(),
  },
  'intrexx-blue': {
    DEFAULT: 'hsl(205, 90%, 15%)', // #042D4B, hwb(205, 2%, 71%)
  },
  'be-primary': {},
};

/** @type {import('tailwindcss').TailwindConfig} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  // for generating dynamic forms
  safelist: [
    'col-span-1',
    'col-span-2',
    'col-span-3',
    'col-span-4',
    'col-span-5',
    'col-span-6',
    'col-span-7',
    'col-span-8',
    'col-span-9',
    'col-span-10',
    'col-span-11',
    'col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    extend: {
      colors: beColors,
      fontSize: {
        h1: [
          '4rem',
          {
            lineHeight: '1.1',
          },
        ],
        h2: ['2.5rem'],
        h5: ['1.1rem'],
      },
      boxShadow: {
        'card-hover': '0 8px 15px 0 rgba(0, 0, 0, .15)',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    fontFamily: {
      sans: ['Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'],
      raleway: ['Raleway', 'Helvetica Neue', 'Arial', 'sans-serif'],
    },
  },
  plugins: [],
};
