// @ts-ignore
import * as Klaro from 'klaro';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import klaroConfig from 'components/config.klaro';
import Menu from 'components/primitives/Menu';
import Footer, {
  IFooterCompany,
  IFooterContact,
  IFooterPrivacyPolicies,
} from 'components/static/Footer';
import Map from 'components/static/Map';

import 'i18n/config';

import { ISuluPage } from 'types/interfaces/sulu/IPages';

import suluPageHandler from 'utils/sulu/pageHandler';

import { COMPANY_NAME } from '../constants';

const HeadlessTemplatePage = ({ data }: { data: ISuluPage }) => {
  const { t } = useTranslation();
  const { content, urls } = data;
  const { title } = content;

  /**
   * Init Klaro only once
   * */
  useEffect(() => {
    // we assign the Klaro module to the window, so that we can access it in JS
    // @ts-ignore
    window.klaro = Klaro;
    // @ts-ignore
    window.klaroConfig = klaroConfig;
    // @ts-ignore
    Klaro.setup(klaroConfig);
    // we set up Klaro with the config
    const klaroCookie = window.document.cookie.match(
      // eslint-disable-next-line prefer-regex-literals
      new RegExp('(^| )klaroStorage=([^;]+)'),
    );
    // if klaroCookie is set, take no further action
    if (!klaroCookie) {
      // @ts-ignore
      Klaro.show();
    }
  }, []);

  /**
   * Track page change if tracking is enabled
   * */
  useEffect(() => {
    // update document title after page change
    document.title = `bitExpert AG | ${title}`;
    // scroll to top
    const scrollToOptions = { top: 0, behavior: 'instant' };
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle,no-multi-assign,@typescript-eslint/naming-convention
    const _paq = (window._paq = window._paq || []);
    // @ts-ignore
    if (window.enableTracking) {
      // eslint-disable-next-line no-restricted-globals
      _paq.push(['setCustomUrl', location.pathname]);
      _paq.push(['setDocumentTitle', document.title]);
      _paq.push(['trackPageView']);
    }
  }, [title]);

  useEffect(() => {
    // remove old alternate entries from the html
    document.querySelectorAll("link[rel='alternate']").forEach(e => e.remove());
    // add new alternate entries
    Object.entries(urls).forEach(([languageCode, url]) => {
      const link = document.createElement('link');
      link.hreflang = languageCode;
      link.rel = 'alternate';
      // not sure if we also need protocol and host
      link.href = `/${languageCode}${url}`;
      document.head.appendChild(link);

      // TODo: what to do if one of the languages is missing?
    });
  }, [urls]);

  const footerCompany: IFooterCompany = {
    name: COMPANY_NAME,
    locations: [
      {
        name: t('general.germany'),
        address: 'Rudolf-Diesel-Str. 40-46<br/>68169 Mannheim',
      },
      {
        name: t('general.romania'),
        address: 'Bd. Regina Maria no.19<br/>040122 Bucharest',
      },
    ],
  };

  const footerContact: IFooterContact = {
    title: t('general.contact'),
    address: {
      name: COMPANY_NAME,
      email: 'info@bitExpert.de',
      phone: '+49 621 150 217 00',
    },
  };

  const footerPrivacyPolicies: IFooterPrivacyPolicies = {
    title: t('components.footer.privacyPolicies'),
    links: [
      {
        name: t('components.footer.imprint'),
        url: t('components.footer.imprintLink'),
      },
      {
        name: t('components.footer.dataPrivacy'),
        url: t('components.footer.dataPrivacyLink'),
      },
    ],
  };

  return (
    <ParallaxProvider>
      <div className='max-w-none'>
        <Menu />
        {suluPageHandler(data)}
        <Map id='map' latLng={[49.5228474, 8.4655344]} />
        <div id='mapImage' className='relative'>
          <img
            src='/assets/map/map-image.jpg'
            className='w-full'
            alt={t('altTexts.map')}
          />
        </div>
        <Footer
          company={footerCompany}
          contact={footerContact}
          privacyPolicies={footerPrivacyPolicies}
        />
      </div>
    </ParallaxProvider>
  );
};

// <pre className='overflow-hidden'>{JSON.stringify(data, null, 2)}</pre>

export default HeadlessTemplatePage;
