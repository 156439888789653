import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBTextRow from './ZSCBTextRow';

const ZSCBMultipleTextRows = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.MULTIPLE_TEXT_ROWS),
  title: z.string(),
  text_rows: z.array(ZSCBTextRow),
  collapsible: z.boolean().optional(),
});

export default ZSCBMultipleTextRows;
