import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTeamwork = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TEAMWORK),
});

export default ZSCBTeamwork;
