import React from 'react';

import { ESuluContentBlockSplitVariants } from 'types/enums/sulu/EBackendData';
import { ISCBSplitContent } from 'types/interfaces/sulu/IContentBlocks';

import suluBlockHandler from 'utils/sulu/blockHandler';

const SplitContent = ({
  title,
  variant,
  left,
  right,
}: Pick<ISCBSplitContent, 'title' | 'variant' | 'left' | 'right'>) => {
  const getGridCols = (isLeft: boolean): string => {
    if (isLeft) {
      switch (variant) {
        case ESuluContentBlockSplitVariants['30_70']:
          return 'col-span-3';
        case ESuluContentBlockSplitVariants['70_30']:
          return 'col-span-7';
        case ESuluContentBlockSplitVariants['50_50']:
        default:
          return 'col-span-5';
      }
    }
    switch (variant) {
      case ESuluContentBlockSplitVariants['30_70']:
        return 'col-span-7';
      case ESuluContentBlockSplitVariants['70_30']:
        return 'col-span-3';
      case ESuluContentBlockSplitVariants['50_50']:
      default:
        return 'col-span-5';
    }
  };

  return (
    <div className='narrowWidth'>
      {title && (
        <h2
          className='text-center'
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <div className='lg:grid lg:grid-cols-10'>
        <div className={getGridCols(true)}>
          <div className='left-part'>
            {left &&
              left.map((block, i) =>
                // @ts-ignore
                suluBlockHandler(block, i),
              )}
          </div>
        </div>
        <div className={getGridCols(false)}>
          <div className='right-part'>
            {right &&
              right.map((block, i) =>
                // @ts-ignore
                suluBlockHandler(block, i),
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitContent;
