import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBImageOnly = ZSCBBase.omit({
  background_variant: true,
}).extend({
  type: z.literal(ESuluContentBlockTypes.IMAGE_ONLY),
  media: ZSuluMedia,
  has_narrow_width: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  is_centered: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
});

export default ZSCBImageOnly;
