import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  generateFieldNameForSulu,
  getFormIdFromMetaData,
  getFormNameFromMetaData,
} from 'components/form';
import generateFormField from 'components/form/generateFormField';
import { IChip } from 'components/primitives/ChipComponent/Chip';
import Spinner from 'components/static/Spinner';

import { ESuluFormFields } from 'types/enums/sulu/EBackendData';
import { ISuluContactForm } from 'types/interfaces/sulu/IPages';

export default ({ contactFormData }: { contactFormData: ISuluContactForm }) => {
  const { t } = useTranslation();
  const data = contactFormData.content.form[0];
  const [selectedChips, setSelectedChips] = useState<IChip[]>([]);
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<Response | null>(null);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  useEffect(() => {
    if (response) {
      setIsLoading(false);

      if (response.status === 200) {
        reset();
        // hide success message after 2,5 seconds
        setTimeout(() => {
          setResponse(null);
        }, 2500);
      }
    }
  }, [response]);

  const onSubmit = async (fieldValues: FieldValues) => {
    setResponse(null);
    setIsLoading(true);

    const formData = new FormData();
    const formId = getFormIdFromMetaData(contactFormData);
    const formName = getFormNameFromMetaData(contactFormData);

    formData.append(
      generateFieldNameForSulu(formName, formId, ESuluFormFields.TEXT),
      fieldValues[ESuluFormFields.TEXT],
    );

    formData.append(
      generateFieldNameForSulu(formName, formId, ESuluFormFields.EMAIL),
      fieldValues[ESuluFormFields.EMAIL],
    );

    formData.append(
      generateFieldNameForSulu(formName, formId, ESuluFormFields.TEXTAREA),
      fieldValues[ESuluFormFields.TEXTAREA],
    );

    data.meta.map(metaField =>
      formData.append(
        generateFieldNameForSulu(formName, formId, metaField.property_path),
        metaField.data.toString(),
      ),
    );

    if (selectedChips.length > 0) {
      selectedChips.forEach((chip: IChip) => {
        formData.append(
          `${generateFieldNameForSulu(
            formName,
            formId,
            ESuluFormFields.CHECKBOX_MULTIPLE,
          )}[]`,
          chip.label,
        );
      });
    }

    formData.append('submit', 'null');

    const res = await fetch(window.location.href, {
      method: 'POST',
      body: formData,
    });

    if (res.status === 200) {
      reset();
    }

    setResponse(res);
  };

  const nameInput = contactFormData.content.form[0].inputs.filter(
    input => input.type === ESuluFormFields.TEXT,
  )[0];
  const emailInput = contactFormData.content.form[0].inputs.filter(
    input => input.type === ESuluFormFields.EMAIL,
  )[0];
  const checkboxMultipleInput = contactFormData.content.form[0].inputs.filter(
    input => input.type === ESuluFormFields.CHECKBOX_MULTIPLE,
  )[0];
  const textAreaInput = contactFormData.content.form[0].inputs.filter(
    input => input.type === ESuluFormFields.TEXTAREA,
  )[0];

  const getBackgroundColor = () => {
    if (!response) {
      return 'bg-transparent';
    }

    return response.status === 200 ? 'bg-darkblue' : 'bg-magenta';
  };

  return (
    <div className='contact-form'>
      <h3 className='text-center mb-11'>{contactFormData.content.title}</h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='grid grid-cols-1 md:grid-cols-2 md:gap-4'
      >
        <div>
          {generateFormField(nameInput, register, 'name_input')}
          {generateFormField(emailInput, register, 'email_input')}
          {generateFormField(
            checkboxMultipleInput,
            register,
            'checkbox_multiple_input',
            setSelectedChips,
          )}
        </div>
        <div className='flex flex-col mt-4 md:mt-0'>
          {generateFormField(textAreaInput, register, 'text_area_input')}
          <div className='relative overflow-hidden z-[1]'>
            <button
              type='submit'
              className='flex items-center justify-center gap-2.5 appearance-none w-full bg-primary text-primary-lighter p-4 font-medium text-center hover:bg-primary-hover transition-colors ease-in-out duration-300'
              onClick={e => {
                // @ts-ignore
                const rect = e.target.getBoundingClientRect();
                setCoords({
                  x: e.clientX - rect.left,
                  y: e.clientY - rect.top,
                });
              }}
              disabled={isLoading}
            >
              <span>{data.submit.label || 'Submit'}</span>
              {isLoading && <Spinner />}
            </button>
            {isRippling && (
              <span
                className='ripple'
                style={{
                  left: coords.x,
                  top: coords.y,
                }}
              />
            )}
          </div>
          {/* success/error message */}
          <div
            className={`text-white font-bold px-6 py-3 transition-all ease-in-out duration-300 ${
              response ? 'translate-y-0' : '-translate-y-full'
            } ${getBackgroundColor()}`}
          >
            {!response && <span>&nbsp;</span>}
            {response && response.status === 200 && (
              <span>{t('snippets.contactForm.messages.success')}</span>
            )}
            {response && response.status !== 200 && (
              <span>{t('snippets.contactForm.messages.error')}</span>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
