import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from 'react-use';

import Slider from 'components/primitives/Slider';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { ISSPageHeader } from 'types/interfaces/sulu/ISnippets';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

export default ({ content }: ISSPageHeader) => {
  const { image, title, subtitle } = content;
  const bgImageStyle = {
    backgroundImage: `url(${image.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  const { width } = useWindowSize();
  const isMobile =
    width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg);

  if (content.isHeroHeader) {
    const {
      button_text_1: buttonText1,
      button_link_1: buttonLink1,
      button_text_2: buttonText2,
      button_link_2: buttonLink2,
      customer_banner_title: customerBannerTitle,
      customer_banner_images: customerBannerImages,
    } = content;

    const headerHero = (
      <div
        className='flex flex-col items-center justify-center pt-14 lg:h-screen'
        style={bgImageStyle}
      >
        <div className='flex flex-1 flex-col justify-center w-full px-10 lg:px-20'>
          <div className='flex flex-col text-center'>
            <h1
              className='font-raleway leading-[1.1] text-[2rem] lg:text-h1 !leading-tight font-bold text-primary-lighter pt-10 pb-3 lg:pt-8 lg:pb-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <h3 className='font-light text-primary-lighter pt-3 lg:pt-4 pb-11 m-0'>
              {subtitle}
            </h3>
          </div>
          <div className='flex flex-col md:flex-row justify-center items-center gap-8 mt-5 mb-12 lg:my-10'>
            <a
              className='border border-white bg-white text-primary font-medium w-full md:w-64 py-3 text-center hover:border hover:border-white hover:text-primary-lighter hover:bg-transparent'
              href={buttonLink1 || ''}
            >
              {buttonText1}
            </a>
            <a
              className='bg-transparent text-primary-lighter border border-white/50 w-full md:w-64 py-3 text-center
                  font-medium hover:text-primary hover:border-primary hover:bg-white'
              href={buttonLink2 || ''}
            >
              {buttonText2}
            </a>
          </div>
        </div>
        {customerBannerImages.length > 0 && (
          <div className='flex flex-col px-10 lg:px-20 mb-12 w-full'>
            {customerBannerTitle && (
              <p className='font-bold text-primary-lightest my-3'>
                {customerBannerTitle}
              </p>
            )}
            <div className='flex flex-1 overflow-x-hidden'>
              <Slider
                options={{
                  arrows: false,
                  autoplay: true,
                  interval: 3000,
                  rewind: true,
                  perPage: 8,
                  pagination: false,
                  breakpoints: {
                    [getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) - 1]:
                      {
                        perPage: 6,
                        drag: true,
                      },
                    [getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) - 1]:
                      {
                        perPage: 3,
                        drag: true,
                      },
                  },
                  gap: '1rem',
                }}
                hasLightPagination
              >
                {customerBannerImages?.map(cbi => (
                  <img
                    className='min-w-[1rem] mr-6 pr-6'
                    key={cbi.id + cbi.url}
                    src={cbi.url}
                    alt={cbi.description || cbi.title}
                  />
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    );
    return !isMobile ? (
      <Parallax
        translateY={['0%', '100%']}
        shouldAlwaysCompleteAnimation
        className='parent page-header'
      >
        {headerHero}
      </Parallax>
    ) : (
      <div className='page-header-wrapper'>{headerHero}</div>
    );
  }

  const headerNormal = (
    <div className='pt-14' style={bgImageStyle}>
      <div className='min-h-full px-10 lg:px-20 text-center flex flex-col justify-between overflow-hidden'>
        <h1
          className='font-raleway leading-[1.1] text-[2rem] lg:text-h1 !leading-tight font-bold text-primary-lighter pt-10 pb-3 lg:pt-8 lg:pb-4'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <h3 className='font-light pt-3 text-primary-lighter lg:pt-4 pb-11 m-0'>
          {subtitle}
        </h3>
      </div>
    </div>
  );
  return !isMobile ? (
    <Parallax
      translateY={['0%', '100%']}
      shouldAlwaysCompleteAnimation
      className='parent relative z-[-1] page-header'
    >
      {headerNormal}
    </Parallax>
  ) : (
    <div className='page-header-wrapper'>{headerNormal}</div>
  );
};
