import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBImageWithText from './ZSCBImageWithText';

const ZSCBMultipleImageText = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.MULTIPLE_IMAGE_TEXT),
  title: z.string(),
  images: z.array(ZSCBImageWithText),
});

export default ZSCBMultipleImageText;
