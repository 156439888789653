import React from 'react';

import BlockContainer from 'components/blocks/_blockContainer';
import ApplyForm from 'components/snippets/ApplyForm';

import { ESuluContentBlockBackgroundVariants } from 'types/enums/sulu/EBackendData';
import { ISuluApplyForm } from 'types/interfaces/sulu/IPages';

export default (applyFormData: ISuluApplyForm): JSX.Element => (
  <BlockContainer
    backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHTEST}
  >
    <ApplyForm applyFormData={applyFormData} />
  </BlockContainer>
);
