import React from 'react';

export interface IChip {
  label: string;
  selected: boolean;
}

interface IChipProps {
  chip: IChip;
  onChipClick: (chip: IChip) => void;
}

const Chip = ({ chip, onChipClick }: IChipProps) => {
  const handleOnClick = () => {
    onChipClick(chip);
  };

  return (
    <button
      type='button'
      className={`focus-visible:outline focus-visible:outline-2 text-primary-text font-light rounded-full px-4 py-1 m-1.5 border ${
        chip.selected
          ? 'bg-primary-light border-primary'
          : 'bg-primary-lighter border-gray-lighter'
      }`}
      onClick={handleOnClick}
    >
      {chip.label}
    </button>
  );
};

export default Chip;
