import React from 'react';

import BlockContainer from 'components/blocks/_blockContainer';
import { ContactForm } from 'components/snippets';
import TechCloud from 'components/static/TechCloud';

import { ESuluContentBlockBackgroundVariants } from 'types/enums/sulu/EBackendData';
import { ISuluContactForm } from 'types/interfaces/sulu/IPages';

export default (contactFormData: ISuluContactForm): JSX.Element => (
  <BlockContainer
    backgroundVariant={ESuluContentBlockBackgroundVariants.LIGHTEST}
  >
    <div className='grid grid-cols-1 gap-12 md:gap-24 lg:grid-cols-2'>
      <ContactForm contactFormData={contactFormData} />
      <TechCloud />
    </div>
  </BlockContainer>
);
