import { t } from 'i18next';
import React from 'react';

import CustomerIcon from 'assets/icons/Customer.svg';
import PartnersIcon from 'assets/icons/Partners.svg';
import SolutionsIcon from 'assets/icons/Solutions.svg';

import Slider from '../../primitives/Slider';

const title = t('components.partnerships.intro.title');

const content = [
  {
    icon: <PartnersIcon id='partners-icon' className='w-48 text-primary' />,
    count: 10,
    name: t('components.partnerships.intro.happyPartners'),
  },
  {
    icon: <CustomerIcon id='customer-icon' className='w-48 text-primary' />,
    count: 50,
    name: t('components.partnerships.intro.customers'),
  },
  {
    icon: <SolutionsIcon id='solutions-icon' className='w-48 text-primary' />,
    count: 26,
    name: t('components.partnerships.intro.individualSolutions'),
  },
];

export default () => {
  const createItems = () =>
    content.map(item => (
      <div key={item.name} className='flex flex-col items-center'>
        {item.icon}
        <div className='text-primary text-6xl font-light'>{item.count}</div>
        <p className='text-gray-light text-center'>{item.name}</p>
      </div>
    ));

  return (
    <div>
      <h2 className='text-center font-bold'>{title}</h2>
      <div className='mt-10 lg:mb-2'>
        <Slider>{createItems()}</Slider>
      </div>
    </div>
  );
};
