import { z } from 'zod';

import {
  ESuluContentBlockTextMediaOrientations,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBTextMedia = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TEXT_MEDIA),
  title: z.string(),
  has_narrow_width: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  has_centered_title: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  text_html_fullwidth_default: z
    .boolean()
    .or(z.null()) // null is default
    .or(z.undefined()),
  link: z.string().or(z.null()),
  media: ZSuluMedia.or(z.null()),
  media_orientation: z.nativeEnum(ESuluContentBlockTextMediaOrientations),
  text_html: z.string(),
});

export default ZSCBTextMedia;
