import { t } from 'i18next';
import React from 'react';

import Locations from './Locations';
import Team from './Team';

const team = {
  title: t('components.team.title'),
  intros: [
    t('components.team.bitExpert'),
    t('components.team.nerds'),
    t('components.team.learners'),
  ],
  copy: t('components.team.copy'),
};

const locations = {
  title: t('components.locations.title'),
  locations: [
    {
      name: t('general.germany'),
      address: t('general.germanyAddress'),
    },
    {
      name: t('general.romania'),
      address: t('general.romaniaAddress'),
    },
  ],
  note: t('components.locations.note'),
};

const TeamAndLocations = () => (
  <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32 mt-3 mb-5 lg:mb-2'>
    {team && <Team {...team} />}
    {locations && <Locations {...locations} />}
  </div>
);

export default TeamAndLocations;
