import React from 'react';

import { ISCBIFrame } from 'types/interfaces/sulu/IContentBlocks';

const IFrame = ({ url }: ISCBIFrame) => (
  <iframe
    src={url}
    title={`iframe for url ${url}`}
    width='100%'
    height='350px'
  />
);

export default IFrame;
