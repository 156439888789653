import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTeamAndLocations = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TEAM_AND_LOCATIONS),
});

export default ZSCBTeamAndLocations;
