import { z } from 'zod';

import {
  ESuluContentBlockTypes,
  ESuluPageTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBContent from './ZSCBContent';

const ZSCBPage = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.PAGE),
  template: z.nativeEnum(ESuluPageTypes),
  id: z.string(),
  content: ZSCBContent,
});

export default ZSCBPage;
