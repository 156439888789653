import React from 'react';

import { Card } from 'components/blocks';

import { ISCBCardSlider } from 'types/interfaces/sulu/IContentBlocks';

import Slider from '../primitives/Slider';

export default ({ title, cards }: ISCBCardSlider) => (
  <div>
    {title && <h2 className='text-center mb-9'>{title}</h2>}
    <Slider>
      {cards.map(card => (
        <Card key={card.id} {...card.content} />
      ))}
    </Slider>
  </div>
);
