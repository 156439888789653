import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTimeline = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TIMELINE),
});

export default ZSCBTimeline;
