import React from 'react';

import CardImage from 'components/primitives/CardImage';

import { ISCBCard } from 'types/interfaces/sulu/IContentBlocks';

const SimpleCard = ({
  title,
  image,
  button_link,
  open_in_new_tab,
  description_html,
  button_text,
}: ISCBCard) => (
  <CardImage
    copy={description_html || ''}
    image={image?.url}
    title={title || ''}
    button={{
      label: button_text || '',
      url: button_link || '',
      target: open_in_new_tab ? '_blank' : '_self',
    }}
  />
);

export default SimpleCard;
