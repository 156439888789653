import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [allAvailableLanguages, setAllAvailableLanguages] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const allAlternateLinks = document.querySelectorAll('[rel="alternate"]');
    const availableLanguages: string[] = [];
    allAlternateLinks.forEach(link => {
      const lang = link.getAttribute('hreflang');
      if (!lang) {
        return;
      }
      availableLanguages.push(lang);
    });
    setAllAvailableLanguages(availableLanguages);
  }, []);

  const updateUrl = (language: string) => {
    const alternateLink = document.querySelector(
      `[rel="alternate"][hreflang="${language}"]`,
    );
    if (!alternateLink) {
      return;
    }
    const href = alternateLink.getAttribute('href');
    if (!href) {
      return;
    }
    window.location.href = href;
  };

  const createLanguages = () =>
    allAvailableLanguages.map(language => (
      <button
        key={language}
        type='button'
        className={`${
          i18n.resolvedLanguage === language ? 'text-white' : 'text-white/50'
        } hover:text-white font-sans lg:font-raleway font-medium text-xl lg:text-sm px-2 border-r-2 border-white last:border-none transition-colors ease-in-out duration-300`}
        onClick={() => updateUrl(language)}
      >
        {language.toUpperCase()}
      </button>
    ));

  return <div className='flex items-center'>{createLanguages()}</div>;
};

export default LanguageSwitch;
