import React from 'react';

import CardImage from 'components/primitives/CardImage';

import { ISCBContent } from 'types/interfaces/sulu/IContentBlocks';

import getCurrentLanguage from 'utils/sulu/getCurrentLanguage';

import './Card.css';

const Card = ({
  title,
  url,
  open_in_new_tab,
  card_image,
  card_title,
  card_description_html,
  card_button_text,
}: ISCBContent) =>
  // TODO update condition -> doesn't make sense
  card_image && card_title && card_description_html && card_button_text ? (
    <CardImage
      copy={card_description_html || ''}
      image={card_image?.url}
      title={card_title || title}
      button={{
        label: card_button_text,
        url: `/${getCurrentLanguage}${url}` || '',
        target: open_in_new_tab ? '_blank' : '_self',
      }}
    />
  ) : (
    <div />
  );

export default Card;
